import React from 'react';

import './ContactListItem.css';

const ContactListItem = (props) => {
    return (
        <li>
            <span className="text-uppercase">{props.title}</span>
            {props.value.map((v, index, array) => {
                return (
                    <React.Fragment key={v}>
                        {v}
                        {(index + 1) < array.length ? <br /> : null}
                    </React.Fragment>
                )
            })}
        </li>
    );
};

export default ContactListItem;