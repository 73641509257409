import React from 'react';

import ProductItem from '../ProductItem/ProductItem';

const ProductList = (props) => {
    return (
        <React.Fragment>
            {props.productsList.map((p, index, array) => !p.inactive ?
                props.fromHome && index > 2 ?
                    null
                    :
                    <ProductItem
                        key={p.id}
                        {...p}
                        withImage={props.withImage}
                        flex={props.flex}
                        pathname={props.pathname}
                        fromHome={props.fromHome}
                        lastItem={index === (array.length - 1)} />
                : null)}
        </React.Fragment>
    );
};

export default ProductList;