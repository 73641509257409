import React, { useState, useCallback } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

import './Contacto.css';
import ProductTitle from '../../../components/Products/ProductTitle/ProductTitle';
import ContactFormItem from '../../../components/Contact/ContactFormItem/ContactFormItem';
import ContactList from '../../../components/Contact/ContactList/ContactList';
// import { contactScreen } from "../../../data/GeneralProps";

const Contacto = () => {
    const contactScreen = useSelector(state => state.data.generalProps.contactScreen);
    contactScreen.contactForm[1].validationRegister.pattern.value = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState(false);
    const [messageResponse, setMessageResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(async (data) => {
        const formData = new FormData();
        formData.append('toMail', contactScreen.contactList[0].value[0]);
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('message', data.message);
        setError(false);
        setMessageResponse(null);
        setLoading(true);
        try {
            const response = await axios.post('/php/sendmail.php', formData);
            setLoading(false);
            setError(response.data.error);
            setMessageResponse(response.data.message);
        } catch (err) {
            setError(true);
            setMessageResponse(err.message);
            setLoading(false);
        }
    }, [contactScreen.contactList]);

    return (
        <Container fluid className="text-white py-5">
            <ProductTitle textLight="Póngase en " textBold="Contacto" titleType="h1" />
            <Row className="p-5 justify-content-center">
                <Col md="7" lg="6" xl="5" className="mb-5 mb-md-0">
                    <form className="custom-form" onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="mb-4 custom-header">Contáctenos</h3>

                        {contactScreen.contactForm.map(c => {
                            let errorMessage = null;
                            for (const k in errors) {
                                if (k === c.id) {
                                    errorMessage = errors[k].message ? errors[k].message : null;
                                }
                            }
                            return (
                                <React.Fragment key={c.id}>
                                    <ContactFormItem {...c} reference={register} errorMessage={errorMessage} fromContact={true} />
                                </React.Fragment>
                            )
                        })}

                        <div className="form-group mt-5">
                            <button type="submit" className="btn custom-btn px-4 py-3">
                                {loading ? <div className="spinner">
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                </div> : "Enviar mensaje"}
                            </button>
                        </div>
                        {messageResponse ?
                            error ?
                                <span className="custom-error-message">{messageResponse}</span>
                                :
                                <span className="custom-success-message">{messageResponse}</span>
                            : null}
                    </form>
                </Col>
                <Col md="5" lg="4" xl="3" className="pl-md-5 mt-3 mb-mt-0">
                    <h3 className="mb-4 custom-header">Nuestros datos</h3>
                    <ContactList list={contactScreen.contactList} />
                </Col>
            </Row>
        </Container >
    );
};

export const componentName = "Contacto";
export default Contacto;