import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Col, Container, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

import './Nosotros.css';
import ProductTitle from '../../../components/Products/ProductTitle/ProductTitle';
import CardProperty from '../../../components/CardProperty/CardProperty';
import TitleLightBold from '../../../components/TitleLightBold/TitleLightBold';
// import * as aboutData from '../../../data/About';

const heightWindow = window.innerHeight;

const Nosotros = () => {
    const aboutData = useSelector(state => state.data.about);
    const [visibleCountUps, setVisibleCountUps] = useState(false);

    const handleVisibleCountUps = useCallback(() => {
        if (visibleCountUps === false) {
            const posTopElement = document.getElementById("divVisibleCountUps").getBoundingClientRect().y;
            const posBottomElement = posTopElement + (document.getElementById("divVisibleCountUps").getBoundingClientRect().height / 2);
            if ((heightWindow) > (posBottomElement)) {
                setVisibleCountUps(true);
                window.removeEventListener('scroll', handleVisibleCountUps, true);
            }
        }
    }, [visibleCountUps]);

    useEffect(() => {
        setVisibleCountUps(heightWindow > (document.getElementById("divVisibleCountUps").getBoundingClientRect().y + (document.getElementById("divVisibleCountUps").getBoundingClientRect().height / 2)));
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleVisibleCountUps, true);
        return (() => {
            window.removeEventListener('scroll', handleVisibleCountUps, true);
        });
    }, [handleVisibleCountUps]);

    return (
        <React.Fragment>
            <Container fluid className="text-center text-white px-5 pt-5 pb-0">
                <Row className="align-items-start">
                    <Col lg="6" className="p-5">
                        <TitleLightBold textLight="Nuestra " textBold="Historia" titleType="h1" />
                    </Col>
                    <Col lg="6" className="text-justify custom-paragraph p-sm-5">
                        {/* {aboutData.aboutHistory.map(h => <p className="mb-5 font-italic" key={h.id}>{parse(h.description)}</p>)} */}
                        <span className="custom-text-about">{parse(aboutData.history)}</span>
                    </Col>
                </Row>
            </Container>
            <div className="custom-div-image" style={{ background: "url(./assets/images/about.jpg)" }}></div>
            <Container fluid className="p-5" style={{ background: '#333' }}>
                <Row className="align-items-start justify-content-center" id="divVisibleCountUps" name="divVisibleCountUps">
                    {visibleCountUps ? aboutData.countUps.map(c => <Col lg="3" md="6" className="p-md-5 text-center align-items-start" key={c.id}>
                        <CountUp {...(c.plus ? { suffix: "+" } : null)} end={c.qty} duration={5} className="custom-countup" />
                        <p className="custom-paragraph pt-4">{c.description}</p>
                    </Col>) : null}
                </Row>
            </Container>
            <Container fluid className="p-5 mt-5">
                <Row className="p-sm-5 justify-content-center">
                    {aboutData.aboutPrinciples.map(p => <CardProperty key={p.id} {...p} />)}
                </Row>
                <hr className="custom-hr" />
                <ProductTitle textLight="Póngase en " textBold="Contacto" titleType="h1" className="pt-5" />
                <div className="text-center mt-2 py-5">
                    <Link to={{ pathname: '/contacto' }}><button className="btn custom-btn px-4 py-3">Escriba o Llámenos</button></Link>
                </div>
            </Container>
        </React.Fragment>
    );
};

export const componentName = "Nosotros";
export default Nosotros;