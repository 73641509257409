import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';

//Bootstrap
import { Container } from 'react-bootstrap';

// import ProductTitle from '../../../../../components/Products/ProductTitle/ProductTitle';
// import { renderButton, checkSignedIn } from "../../../../../utils/utils";
// import Report from '../../../../../components/Report/Report';

const Inicio = () => {
    // const [isSignedIn, setIsSignedIn] = useState(false);
    // const [auth, setAuth] = useState(false);

    // const updateSignin = (signedIn) => {
    //     setIsSignedIn(signedIn);
    //     if (!signedIn) {
    //         renderButton(setAuth);
    //     }
    // };

    // const init = () => {
    //     checkSignedIn()
    //         .then((signedIn) => {
    //             updateSignin(signedIn);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    // useEffect(() => {
    //     window.gapi.load("auth2", init);
    // });

    return (
        <Container fluid className="py-5">
            {/* <ProductTitle textLight="Google " textBold="Analytics" titleType="h1" />
            {!isSignedIn && !auth ?
                <div id="signin-button" className="d-flex justify-content-center my-5"></div>
                :
                // <p className="text-center text-white my-5">Obteniendo datos...</p>
                <Report />
            } */}
            <Redirect to="/admin/menu/encordadoras" />
        </Container>
    );
};

export const componentName = "Inicio";
export default withRouter(Inicio);