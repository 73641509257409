export const listItems = {
    brandMobile: {
        title: "ProString Machine",
        classes: "m-auto custom-logo-collapse",
        brand: true,
        path: "/",
        exact: false,
        img: 'http://www.prostringmachine.com/assets/images/logo/small/dark.png'
    },
    cartMobile: {
        title: "shopping-cart",
        classes: "custom-logo-collapse",
        brand: false,
        path: "/checkout",
        exact: false
    },
    items: [
        {
            title: "ProString Machine",
            classes: "ml-2 mr-4 custom-logo",
            brand: true,
            path: "/",
            exact: false,
            img: 'http://www.prostringmachine.com/assets/images/logo/medium/dark.png'
        },
        {
            title: "Inicio",
            classes: "mx-2 custom-border-bottom-navbar-item",
            brand: false,
            path: "/",
            exact: true
        },
        {
            title: "Encordadoras",
            classes: "mx-2 custom-border-bottom-navbar-item",
            brand: false,
            path: "/encordadoras",
            exact: false
        },
        {
            title: "Repuestos",
            classes: "mx-2 custom-border-bottom-navbar-item",
            brand: false,
            path: "/repuestos",
            exact: false
        },
        {
            title: "Servicios",
            classes: "mx-2 custom-border-bottom-navbar-item",
            brand: false,
            path: "/servicios",
            exact: false
        },
        {
            title: "Nosotros",
            classes: "mx-2 custom-border-bottom-navbar-item",
            brand: false,
            path: "/nosotros",
            exact: false
        },
        {
            title: "Contacto",
            classes: "mx-2",
            brand: false,
            path: "/contacto",
            exact: true
        },
        {
            title: "shopping-cart",
            classes: "mr-2 ml-4 custom-logo",
            brand: false,
            path: "/checkout",
            exact: false
        }
    ]
};