import React from 'react';

//Bootstrap
import { Row, Col } from 'react-bootstrap';

import TitleLightBold from '../../TitleLightBold/TitleLightBold';

const ProductTitle = (props) => {
    let classes = null;
    if(props.className){
        classes = props.className;
    }
    return (
        <Row className={classes}>
            <Col className="text-center text-white">
                <TitleLightBold textLight={props.textLight} textBold={props.textBold} titleType={props.titleType} />
            </Col>
        </Row>
    );
};

export default ProductTitle;