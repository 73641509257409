import { faAward, faLink, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';

export const ID_MAQUINAS = "PSM-M";
export const ID_REPUESTOS = "PSM-R";
export const ID_SERVICIOS = "PSM-S";

export const products = [
    //Cada objeto de este array es un tipo de producto
    {
        id: ID_MAQUINAS,
        textLight: "Máquinas de ",
        textBold: "Encordar",
        pathname: "/encordadoras",
        withImage: true,
        flex: {
            xs: "",
            sm: "",
            md: "6",
            lg: "5",
            xl: "4"
        },
        productsList: [
            //Cada objeto de este array es un producto de este tipo de producto
            {
                id: "PSM-M001",
                title: "Máquina con Caja",
                description: "Excelente maniobrabilidad, fácil de desarmar y de guardar en su caja autoportable. Sistema de tensionado automático, seguro y confiable, calibrado con instrumentos certificados. Cuenta con 6 apoyos que dan más seguridad y evita la deformación de la raqueta. Todas las partes tienen cromado galvánico en baño electrolítico que evita toda posibilidad de oxidación y corrosión, lo que garantiza el funcionamiento permanente a lo largo de los años.",
                price: 0,
                inactive: false,
                alt: "Máquina con Caja",
                img: "./assets/images/products/psm-m/psm-m001/001-2.jpg",
                imgDetails: ["./assets/images/products/psm-m/psm-m001/001-2.jpg", "./assets/images/products/psm-m/psm-m001/002-2.jpg", "./assets/images/products/psm-m/psm-m001/003-2.jpg", "./assets/images/products/psm-m/extra.jpg"],
                tableDetails: {
                    measures: { outBox: "29cm x 36cm x 74cm", inBox: "16,5cm x 11,5cm (+2,5cm) x 40cm" },
                    colorsAvailable: ["Azul", "Negro"], //Maximo dos colores
                    pinzas: true,
                    guarantee: 12,
                    extras: "Llave Allen para armado"
                },
                mercadolibre: "https://articulo.mercadolibre.com.ar/MLA-609364860-maquina-de-encordar-raquetas-prostring-machine-portatil-_JM"
            },
            {
                id: "PSM-M002",
                title: "Máquina con Barra",
                description: "Al reemplazar la caja por la barra, se convierte en el modelo más liviano de nuestro catálogo. Ocupa un 40% menos de espacio, disminuyendo considerablemente su peso total. Excelente maniobrabilidad, fácil de desarmar y de transportar. Sistema de tensionado automático, seguro y confiable, calibrado con instrumentos certificados. Cuenta con 6 apoyos que dan más seguridad y evita la deformación de la raqueta. Todas las partes tienen cromado galvánico en baño electrolítico que evita toda posibilidad de oxidación y corrosión, lo que garantiza el funcionamiento permanente a lo largo de los años.",
                price: 0,
                inactive: false,
                alt: "Máquina con Barra",
                img: "./assets/images/products/psm-m/psm-m002/001-2.jpg",
                imgDetails: ["./assets/images/products/psm-m/psm-m002/001-2.jpg", "./assets/images/products/psm-m/psm-m002/002-2.jpg", "./assets/images/products/psm-m/psm-m002/003-2.jpg", "./assets/images/products/psm-m/extra.jpg"],
                tableDetails: {
                    measures: { outBox: "20,5cm x 36cm x 74cm", inBox: null },
                    colorsAvailable: ["Silver"], //Maximo dos colores
                    pinzas: true,
                    guarantee: 12,
                    extras: "Llave Allen para armado"
                },
                mercadolibre: "https://articulo.mercadolibre.com.ar/MLA-707363967-maquina-de-encordar-prostring-machine-portatil-liviana-_JM"
            },
            {
                id: "PSM-M003",
                title: "Máquina Bádminton con Caja",
                description: "El nuevo modelo especial para Bádminton, asegurando la estabilidad del pequeño aro que posee la raqueta. Excelente maniobrabilidad, fácil de desarmar y de guardar en su caja autoportable. Sistema de tensionado automático, seguro y confiable, calibrado con instrumentos certificados. Cuenta con 6 apoyos que dan más seguridad y evita la deformación de la raqueta. Todas las partes tienen cromado galvánico en baño electrolítico que evita toda posibilidad de oxidación y corrosión, lo que garantiza el funcionamiento permanente a lo largo de los años.",
                price: 0,
                inactive: false,
                alt: "Máquina Bádminton con Caja",
                img: "./assets/images/products/psm-m/psm-m004/001-2.jpg",
                imgDetails: ["./assets/images/products/psm-m/psm-m004/001-2.jpg", "./assets/images/products/psm-m/psm-m004/002-2.jpg", "./assets/images/products/psm-m/psm-m004/003-2.jpg", "./assets/images/products/psm-m/extra.jpg"],
                tableDetails: {
                    measures: { outBox: "29cm x 36cm x 66cm", inBox: "16,5cm x 11,5cm (+2,5cm) x 40cm" },
                    colorsAvailable: ["Azul", "Negro"], //Maximo dos colores
                    pinzas: true,
                    guarantee: 12,
                    extras: "Llave Allen para armado"
                }
                // mercadolibre: ""
            },
            {
                id: "PSM-M004",
                title: "Máquina Bádminton con Barra",
                description: "El nuevo modelo especial para Bádminton, asegurando la estabilidad del pequeño aro que posee la raqueta. Ocupa un 40% menos de espacio, disminuyendo considerablemente su peso total. Excelente maniobrabilidad, fácil de desarmar y de transportar. Sistema de tensionado automático, seguro y confiable, calibrado con instrumentos certificados. Cuenta con 6 apoyos que dan más seguridad y evita la deformación de la raqueta. Todas las partes tienen cromado galvánico en baño electrolítico que evita toda posibilidad de oxidación y corrosión, lo que garantiza el funcionamiento permanente a lo largo de los años.",
                price: 0,
                inactive: false,
                alt: "Máquina Bádminton con Barra",
                img: "./assets/images/products/psm-m/psm-m004/001-2.jpg",
                imgDetails: ["./assets/images/products/psm-m/psm-m004/001-2.jpg", "./assets/images/products/psm-m/psm-m004/002-2.jpg", "./assets/images/products/psm-m/psm-m004/003-2.jpg", "./assets/images/products/psm-m/extra.jpg"],
                tableDetails: {
                    measures: { outBox: "20,5cm x 36cm x 66cm", inBox: null },
                    colorsAvailable: ["Silver"], //Maximo dos colores
                    pinzas: true,
                    guarantee: 12,
                    extras: "Llave Allen para armado"
                }
                // mercadolibre: ""
            }
        ]
    },
    {
        id: ID_REPUESTOS,
        textLight: "Repuestos ",
        textBold: "Originales",
        pathname: "/repuestos",
        // withImage: true,
        // flex: {
        //     xs: "",
        //     sm: "5",
        //     md: "4",
        //     lg: "4",
        //     xl: "3"
        // },
        flex: {
            xs: "",
            sm: "",
            md: "6",
            lg: "5",
            xl: "4"
        },
        productsList: [
            //Cada objeto de este array es un producto de este tipo de producto
            {
                id: "PSM-R001",
                title: "Pinza de Tensión x2",
                description: "Con la apertura perfecta en sus dientes y el tamaño universal de su cuerpo, estas pinzas son perfectas para no lastimar el encordado, mantener la tensión proporcionada por la máquina y tener el más comodo agarre en su mano. Este repuesto incluye 2 pinzas.",
                price: 0,
                inactive: false
                // alt: "Pinza de Tensión x2",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R002",
                title: "Pinza Individual",
                description: "Con la apertura perfecta en sus dientes y el tamaño universal de su cuerpo, estas pinzas son perfectas para no lastimar el encordado, mantener la tensión proporcionada por la máquina y tener el más comodo agarre en su mano.",
                price: 0,
                inactive: false
                // alt: "Pinza Individual",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R003",
                title: "Caja",
                description: "Robusta, simple, liviana, transportable. El producto preferido por aquellos jugadores que constantemente están viajando. Seleccionable en dos colores diferentes: Azul o Negro.",
                price: 0,
                inactive: false
                // alt: "Caja",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Azul", "Negro"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R004",
                title: "Barra",
                description: "Gracias a esta base logramos que la máquina ocupe un 40% menos de espacio, disminuyendo considerablemente su peso. Su máquina pasará a ser el modelo más liviano del mercado.",
                price: 0,
                inactive: false
                // alt: "Barra",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R005",
                title: "Manopla de tensión",
                description: "Sistema de tensionado automático, seguro y confiable, calibrado con instrumentos certificados. Todas las partes tienen cromado galvánico en baño electrolítico que evita toda posibilidad de oxidación y corrosión, lo que garantiza el funcionamiento permanente a lo largo de los años.",
                price: 0,
                inactive: false
                // alt: "Manopla",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Negro", "Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R006",
                title: "Tensiómetro",
                description: "Incluye el set de la grilla junto con la perilla en donde se selecciona la tensión deseada.",
                price: 0,
                inactive: false
                // alt: "Tensiómetro",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Negro", "Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            // {
            //     id: "PSM-R007",
            //     title: "Tensiómetro II",
            //     description: "",
            //     price: 0,
            //     inactive: false
            //     // alt: "Tensiómetro II",
            //     // img: "./assets/images/none.png",
            //     // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
            //     // inactive: false,
            //     // tableDetails: {
            //     //     measures: { outBox: "?x?x?", inBox: null },
            //     //     colorsAvailable: ["Negro", "Silver"], //Maximo dos colores
            //     //     pinzas: false,
            //     //     guarantee: 6,
            //     //     extras: false
            //     // }
            // },
            // {

            //     id: "PSM-R008",
            //     title: "Tensiómetro III",
            //     description: "",
            //     price: 0,
            //     inactive: false
            //     // alt: "Tensiómetro III",
            //     // img: "./assets/images/none.png",
            //     // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
            //     // inactive: false,
            //     // tableDetails: {
            //     //     measures: { outBox: "?x?x?", inBox: null },
            //     //     colorsAvailable: ["Silver"], //Maximo dos colores
            //     //     pinzas: false,
            //     //     guarantee: 6,
            //     //     extras: false
            //     // }
            // },
            {
                id: "PSM-R007",
                title: "Gatillo",
                description: "Es el disparador que acciona el momento en donde el sistema de tensionado llega a presión adecuada.",
                price: 0,
                inactive: false
                // alt: "Gatillo",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R008",
                title: "Freno de Gatillo",
                description: "Impide tensionar la cuerda en caso de que el sistema no esté colocado correctamente",
                price: 0,
                inactive: false
                // alt: "Freno de Gatillo",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R009",
                title: "Tensor de Cuerda",
                description: "Es la parte del sistema de tensionado que sostiene y tensiona a la cuerda para que llegue a la deseada.",
                price: 0,
                inactive: false
                // alt: "Tensor de Cuerda",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R010",
                title: "Goma de Manopla",
                description: "Posición de la manopla de tensión en donde se ejerce la presión con la mano.",
                price: 0,
                inactive: false
                // alt: "Goma de Manopla",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Negro"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R011",
                title: "Soporte Raqueta Full",
                description: "Incluye ambos aros con todos sus sopoertes y la barra trasversal que los sostiene",
                price: 0,
                inactive: false
                // alt: "Soporte Raqueta Full",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R012",
                title: "Barra Trasversal",
                description: "Es la barra en donde sostiene los soportes para agarrar a la raqueta.",
                price: 0,
                inactive: false
                // alt: "Barra",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R013",
                title: "Soporte Raqueta Individual",
                description: "Incluye un solo aro con sus soportes respectivos",
                price: 0,
                inactive: false
                // alt: "Soporte Raqueta Individual",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R014",
                title: "Aro x2",
                description: "Aro del soporte de raqueta en donde ésta va apoyada para encordarla. Incluye el par.",
                price: 0,
                inactive: false
                // alt: "Aro x2",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R015",
                title: "Aro",
                description: "Aro del soporte de raqueta en donde ésta va apoyada para encordarla.",
                price: 0,
                inactive: false
                // alt: "Aro",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R016",
                title: "Soporte Aro",
                description: "Parte inferior del soporte individual de la raqueta que sostiene al aro.",
                price: 0,
                inactive: false
                // alt: "Soporte Aro",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R017",
                title: "Agarre de Raqueta x4",
                description: "Es uno de los artefactos más pequeños pero uno de los más importantes. Es aquel que sujeta y deja firme a la raqueta para que esta no sufra deformaciones. Incluye el set completo de una máquina, es decir, 4 agarres.",
                price: 0,
                inactive: false
                // alt: "Agarre de Raqueta x4",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R018",
                title: "Agarre de Raqueta x2",
                description: "Es uno de los artefactos más pequeños pero uno de los más importantes. Es aquel que sujeta y deja firme a la raqueta para que esta no sufra deformaciones. Incluye el set de media máquina, es decir, 2 agarres.",
                price: 0,
                inactive: false
                // alt: "Agarre de Raqueta x2",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            },
            {
                id: "PSM-R019",
                title: "Agarre de Raqueta",
                description: "Es uno de los artefactos más pequeños pero uno de los más importantes. Es aquel que sujeta y deja firme a la raqueta para que esta no sufra deformaciones. Incluye 1 agarre individual.",
                price: 0,
                inactive: false
                // alt: "Agarre de Raqueta",
                // img: "./assets/images/none.png",
                // imgDetails: ["./assets/images/none.png", "./assets/images/none.png", "./assets/images/none.png"],
                // inactive: false,
                // tableDetails: {
                //     measures: { outBox: "?x?x?", inBox: null },
                //     colorsAvailable: ["Silver"], //Maximo dos colores
                //     pinzas: false,
                //     guarantee: 6,
                //     extras: false
                // }
            }
        ]
    },
    {
        id: ID_SERVICIOS,
        textLight: "Servicio y ",
        textBold: "Mantenimiento",
        pathname: "/servicios",
        withImage: false,
        flex: {
            xs: "",
            sm: "",
            md: "6",
            lg: "5",
            xl: "4"
        },
        productsList: [
            //Cada objeto de este array es un producto de este tipo de producto
            {
                id: "PSM-S001",
                title: "Calibración",
                description: "Si crees que tu máquina está descalibrada y que no está tensionando correctamente según las libras que indicaste, comunicate con nosotros y agendá un turno para solucionar el problema.",
                price: 0,
                inactive: false
            },
            {
                id: "PSM-S002",
                title: "Reparación",
                description: "¿Se te rompió alguna parte de la maquina? ¿Te falta algún repuesto que no está en nuestro catálogo? Llamanos, comentanos lo que sucedió y te brindaremos toda el soporte necesario",
                price: 0,
                inactive: false
            }
            // {
            //     id: "PSM-S003",
            //     title: "Presupuesto Especial",
            //     description: "Si estás en una situación en donde ningúna de nuestras maquina se puede adptar a tu raqueta, comentanos que necesitás y te pasaremos un presupuesto especial en base a tus requerimientos.",
            //     price: 0,
            //     inactive: false
            // }
        ]
    }
];

//Importar en la primera linea los iconos
export const productsFeatures = [
    {
        id: 1,
        icon: faAward,
        title: "Calidad asegurada",
        description: "La excelente terminación artesanal de cada uno de nuestros productos y la dedicación de cada uno de nuestros operarios hacen la diferencia."
    },
    {
        id: 2,
        icon: faLink,
        title: "Materiales resistentes",
        description: "La robustez es uno de nuestros pilares escenciales por los cuales estamos dominando y liderando el mercado."
    },
    {
        id: 3,
        icon: faDraftingCompass,
        title: "Cada producto es único",
        description: "El aseguramiento de la calidad hasta el más mínimo detalle logra la perfección que puede encontrar en todos nuestros productos."
    }
];