import React, { useState, useCallback } from 'react';

//CKEditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// import './ProductDetail.css';
import ContactFormItem from '../../../../../components/Contact/ContactFormItem/ContactFormItem';
import ProductTitle from '../../../../../components/Products/ProductTitle/ProductTitle';
import { updateAboutUs } from '../../../../../store/actions/data';

const Nosotros = () => {
    const about = useSelector(state => state.data.about);
    const productsFeatures = useSelector(state => state.data.products.productsFeatures);
    const lightId = `Sobre `;
    const boldId = `Nosotros`;
    const dispatch = useDispatch();

    const saveHandler = useCallback(async (item) => {
        if (item.required && item.value === "") {
            item.change(null, { errorMessage: "Este campo no puede estar vacío", success: false }, false);
            return;
        }
        item.change(null, { errorMessage: "", success: false }, true);
        try {
            await dispatch(updateAboutUs(item));
            item.change(null, { errorMessage: "Guardado correctamente", success: true }, false)
        } catch (err) {
            item.change(null, { errorMessage: err.message, success: false }, false)
        }
    }, [dispatch]);

    const [history, setHistory] = useState({ loading: false, error: { errorMessage: "", success: false }, value: about.history });
    const historyHandler = (event, err, loading) => setHistory({ value: (event ? event.target.value : history.value), error: (err ? err : history.err), loading: (loading ? loading : history.loading) });
    const [countUp1, setCountUp1] = useState({ loading: false, error: { errorMessage: "", success: false }, value: about.countUps[0].qty });
    const countUp1Handler = (event, err, loading) => setCountUp1({ value: (event ? event.target.value : countUp1.value), error: (err ? err : countUp1.err), loading: (loading ? loading : countUp1.loading) });
    const [countUp2, setCountUp2] = useState({ loading: false, error: { errorMessage: "", success: false }, value: about.countUps[1].qty });
    const countUp2Handler = (event, err, loading) => setCountUp2({ value: (event ? event.target.value : countUp2.value), error: (err ? err : countUp2.err), loading: (loading ? loading : countUp2.loading) });
    const [countUp3, setCountUp3] = useState({ loading: false, error: { errorMessage: "", success: false }, value: about.countUps[2].qty });
    const countUp3Handler = (event, err, loading) => setCountUp3({ value: (event ? event.target.value : countUp3.value), error: (err ? err : countUp3.err), loading: (loading ? loading : countUp3.loading) });
    const [countUp4, setCountUp4] = useState({ loading: false, error: { errorMessage: "", success: false }, value: about.countUps[3].qty });
    const countUp4Handler = (event, err, loading) => setCountUp4({ value: (event ? event.target.value : countUp4.value), error: (err ? err : countUp4.err), loading: (loading ? loading : countUp4.loading) });
    const [principle1, setPrinciple1] = useState({ icon: about.aboutPrinciples[0].icon, loading: false, error: { errorMessage: "", success: false }, value: about.aboutPrinciples[0].description });
    const principle1Handler = (event, err, loading) => setPrinciple1({ icon: principle1.icon, value: (event ? event.target.value : principle1.value), error: (err ? err : principle1.err), loading: (loading ? loading : principle1.loading) });
    const [principle2, setPrinciple2] = useState({ icon: about.aboutPrinciples[1].icon, loading: false, error: { errorMessage: "", success: false }, value: about.aboutPrinciples[1].description });
    const principle2Handler = (event, err, loading) => setPrinciple2({ icon: principle2.icon, value: (event ? event.target.value : principle2.value), error: (err ? err : principle2.err), loading: (loading ? loading : principle2.loading) });
    const [principle3, setPrinciple3] = useState({ icon: about.aboutPrinciples[2].icon, loading: false, error: { errorMessage: "", success: false }, value: about.aboutPrinciples[2].description });
    const principle3Handler = (event, err, loading) => setPrinciple3({ icon: principle3.icon, value: (event ? event.target.value : principle3.value), error: (err ? err : principle3.err), loading: (loading ? loading : principle3.loading) });    
    const [productFeature1, setProductFeature1] = useState({ icon: productsFeatures[0].icon, loading: false, error: { errorMessage: "", success: false }, value: productsFeatures[0].description });
    const productFeature1Handler = (event, err, loading) => setProductFeature1({ icon: productFeature1.icon, value: (event ? event.target.value : productFeature1.value), error: (err ? err : productFeature1.err), loading: (loading ? loading : productFeature1.loading) });
    const [productFeature2, setProductFeature2] = useState({ icon: productsFeatures[1].icon, loading: false, error: { errorMessage: "", success: false }, value: productsFeatures[1].description });
    const productFeature2Handler = (event, err, loading) => setProductFeature2({ icon: productFeature2.icon, value: (event ? event.target.value : productFeature2.value), error: (err ? err : productFeature2.err), loading: (loading ? loading : productFeature2.loading) });
    const [productFeature3, setProductFeature3] = useState({ icon: productsFeatures[2].icon, loading: false, error: { errorMessage: "", success: false }, value: productsFeatures[2].description });
    const productFeature3Handler = (event, err, loading) => setProductFeature3({ icon: productFeature3.icon, value: (event ? event.target.value : productFeature3.value), error: (err ? err : productFeature3.err), loading: (loading ? loading : productFeature3.loading) });

    const productDetail = [
        [
            { ckeditor: true, required: true, title: "Nuestra Historia", id: "history", idItem: "history", idType: "history", indexValue: 0, type: "text", value: history.value, error: history.error, loading: history.loading, change: historyHandler, placeholder: "Nuestra Historia" }
        ],
        [
            { required: true, title: "Contador 1", id: `countUp${about.countUps[0].id}`, idType: "countUps", idItem: about.countUps[0].id, indexValue: 0, type: "number", value: countUp1.value, error: countUp1.error, loading: countUp1.loading, change: countUp1Handler, placeholder: about.countUps[0].description },
            { required: true, title: "Contador 2", id: `countUp${about.countUps[1].id}`, idType: "countUps", idItem: about.countUps[1].id, indexValue: 1, type: "number", value: countUp2.value, error: countUp2.error, loading: countUp2.loading, change: countUp2Handler, placeholder: about.countUps[1].description },
            { required: true, title: "Contador 3", id: `countUp${about.countUps[2].id}`, idType: "countUps", idItem: about.countUps[2].id, indexValue: 2, type: "number", value: countUp3.value, error: countUp3.error, loading: countUp3.loading, change: countUp3Handler, placeholder: about.countUps[2].description },
            { required: true, title: "Contador 4", id: `countUp${about.countUps[3].id}`, idType: "countUps", idItem: about.countUps[3].id, indexValue: 3, type: "number", value: countUp4.value, error: countUp4.error, loading: countUp4.loading, change: countUp4Handler, placeholder: about.countUps[3].description }
        ],
        [
            { icon: principle1.icon, required: true, title: about.aboutPrinciples[0].title, id: `principle${about.aboutPrinciples[0].id}`, idType: "aboutPrinciples", idItem: about.aboutPrinciples[0].id, indexValue: 0, type: "text", value: principle1.value, error: principle1.error, loading: principle1.loading, change: principle1Handler, placeholder: about.aboutPrinciples[0].title },
            { icon: principle2.icon, required: true, title: about.aboutPrinciples[1].title, id: `principle${about.aboutPrinciples[1].id}`, idType: "aboutPrinciples", idItem: about.aboutPrinciples[1].id, indexValue: 1, type: "text", value: principle2.value, error: principle2.error, loading: principle2.loading, change: principle2Handler, placeholder: about.aboutPrinciples[1].title },
            { icon: principle3.icon, required: true, title: about.aboutPrinciples[2].title, id: `principle${about.aboutPrinciples[2].id}`, idType: "aboutPrinciples", idItem: about.aboutPrinciples[2].id, indexValue: 2, type: "text", value: principle3.value, error: principle3.error, loading: principle3.loading, change: principle3Handler, placeholder: about.aboutPrinciples[2].title }
        ],
        [
            { icon: productFeature1.icon, required: true, title: productsFeatures[0].title, id: `productFeature${productsFeatures[0].id}`, idType: "productsFeatures", idItem: productsFeatures[0].id, indexValue: 0, type: "text", value: productFeature1.value, error: productFeature1.error, loading: productFeature1.loading, change: productFeature1Handler, placeholder: productsFeatures[0].title },
            { icon: productFeature2.icon, required: true, title: productsFeatures[1].title, id: `productFeature${productsFeatures[1].id}`, idType: "productsFeatures", idItem: productsFeatures[1].id, indexValue: 1, type: "text", value: productFeature2.value, error: productFeature2.error, loading: productFeature2.loading, change: productFeature2Handler, placeholder: productsFeatures[1].title },
            { icon: productFeature3.icon, required: true, title: productsFeatures[2].title, id: `productFeature${productsFeatures[2].id}`, idType: "productsFeatures", idItem: productsFeatures[2].id, indexValue: 2, type: "text", value: productFeature3.value, error: productFeature3.error, loading: productFeature3.loading, change: productFeature3Handler, placeholder: productsFeatures[2].title }
        ]
    ];

    return (
        <React.Fragment>
            <Container fluid className="p-5">
                <ProductTitle textLight={lightId} textBold={boldId} titleType="h1" />
                {productDetail.map((c, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Row className="mt-4">
                                {
                                    c.map(i => {
                                        return (
                                            <Col md={12 / c.length < 6 ? 6 : 12 / c.length} key={`${i.idItem}${i.indexValue}`} className="mt-4 p-md-3 p-2">
                                                <h4 className="mb-3 custom-header">{i.title}</h4>
                                                {i.ckeditor ? <div className="overflow-hidden"><CKEditor
                                                    editor={ClassicEditor}
                                                    data={history.value}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        const oEvent = {target: {value: data}}
                                                        historyHandler(oEvent);
                                                    }}
                                                    config={{
                                                        removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'Table'],
                                                        height: '100px',
                                                        language: 'es'
                                                    }}
                                                    onReady={editor => {
                                                        editor.editing.view.change(writer => {
                                                            writer.setStyle(
                                                                "height",
                                                                "15em",
                                                                editor.editing.view.document.getRoot()
                                                            );
                                                        });
                                                    }}
                                                />
                                                    <button onClick={() => saveHandler(i)} className="btn custom-btn py-2 px-3 mt-2 mr-2">
                                                        {i.loading ? <div className="spinner">
                                                            <div className="bounce1"></div>
                                                            <div className="bounce2"></div>
                                                            <div className="bounce3"></div>
                                                        </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                                                    </button>
                                                </div> : <ContactFormItem
                                                        {...i}
                                                        reference={i.reference ? i.reference : null}
                                                        admin
                                                        button={<button onClick={() => saveHandler(i)} className="btn custom-btn py-2 px-3 ml-2">
                                                            {i.loading ? <div className="spinner">
                                                                <div className="bounce1"></div>
                                                                <div className="bounce2"></div>
                                                                <div className="bounce3"></div>
                                                            </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                                                        </button>} />}
                                                {i.error && i.error.errorMessage !== "" ?
                                                    i.error.success ?
                                                        <span className="custom-success-message">{i.error.errorMessage}</span>
                                                        :
                                                        <span className="custom-error-message">{i.error.errorMessage}</span> : null}
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </React.Fragment>
                    );
                })}
            </Container>
        </React.Fragment >
    );
};

export const componentName = "Nosotros";
export default Nosotros;