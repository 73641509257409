import React from 'react';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Col, Container, Row } from 'react-bootstrap';

//FontAwesome
import CustomNavbar from '../../components/Navbar/CustomNavbar';
import TitleLightBold from '../../components/TitleLightBold/TitleLightBold';
import './InitSection.css';

const InitSection = (props) => {
    const dataNavbar = useSelector(state => state.data.navbar);
    const bgImage = `linear-gradient(to top, rgba(0, 0, 0, 0.25) 100%, rgba(0, 0, 0, 0)), ${props.background}`;
    return (
        <section className="custom-init-section" style={{ backgroundImage: bgImage }}>
            <CustomNavbar initSection={true} dataNavbar={dataNavbar}/>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="text-center text-white">
                        <TitleLightBold textLight={props.textLight} textBold={props.textBold} boldDisplayBlock={props.boldDisplayBlock} titleType="h1"/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default InitSection;