import React from 'react';

import { Link } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

import './Checkout.css';

const Checkout = () => {
    return (
        <Container fluid>
            <Row className="p-sm-5 p-4 justify-content-center align-items-center">
                <Col md="6" lg="5" xl="4">
                    <img src="./assets/images/tools.png" className="img-fluid" alt="Tools" />
                </Col>
                <Col md="6" className="text-white text-center">
                    <h1 className="custom-title-404">Oops!</h1>
                    <h3 className="custom-subtitle-404 mt-4">Todavía estamos en construcción</h3>
                    <h5 className="custom-description-404 mt-4 mb-3">Dentro de poco vas a poder realizar tus compras por acá :)</h5>
                    <Link to={{ pathname: "/" }}><button className="btn custom-btn px-4 py-3 my-3">Inicio</button></Link>
                </Col>
            </Row>
        </Container>
    );
};

export const componentName = "Checkout";
export default Checkout;