import React from 'react';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Col, Row } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import './MainDetails.css';

const iconArr = [faFacebookF, faWhatsapp, faEnvelope];

const MainDetails = (props) => {
    const contactScreen = useSelector(state => state.data.generalProps.contactScreen);
    return (
        <Col lg="5" xl="4" className="pl-lg-5">
            <h1 className="text-white mb-4">{props.title}</h1>
            <div className="mb-4">
                <p className="custom-text-light">{props.description}</p>
            </div>
            <div className="mb-4">
                {!props.price || props.price === 0 ?
                    <h3 className="custom-price">Consultar precio</h3>
                    :
                    <h3 className="custom-price">$ {props.price}</h3>}
            </div>
            <Row className="align-items-center justify-content-start mb-4">
                <Col sm="4" md="3" lg="5">
                    {/* Por ahora, este boton de comprar va a ser de telefono */}
                    <button className="btn custom-btn px-4 py-3" onClick={props.click}>Comprar</button>
                </Col>
                {props.mercadolibre ?
                    <Col sm="4" md="3" lg="5">
                        <a href={props.mercadolibre} target="_blank" rel="noopener noreferrer" className="btn custom-btn mt-4 mt-sm-0">
                            <img src="./assets/images/mercadolibre-logo-only.png" alt="Mercado Libre" style={{ maxHeight: '38px' }} />
                        </a>
                    </Col>
                    :
                    null
                }
            </Row>
            <Row className="align-items-center justify-content-start mb-4">
                <Col sm="8" md="9" lg="7" className="mt-4 mt-sm-0 align-items-center d-flex">
                    {contactScreen.socialButtons.map(s => {
                        const icon = iconArr.find(i => i.iconName === s.icon);
                        return <a key={s.id} href={s.href} target="_blank" rel="noopener noreferrer" className="custom-btn-secondary"><span><FontAwesomeIcon icon={icon} /></span></a>
                    })}
                </Col>
            </Row>
            {props.price && props.price !== 0 ?
                <div>
                    <p className="custom-text-light custom-small-indicator">* El precio indicado es para pagos en efectivo.</p>
                </div>
                :
                null
            }
        </Col>
    );
};

export default MainDetails;