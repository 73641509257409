export const navbar = {
    listItems: {
        brandMobile: {
            title: "ProString Machine",
            classes: "m-auto custom-logo-collapse",
            brand: true,
            path: "/admin/menu",
            exact: false,
            img: './assets/images/logo/small/dark.png'
        },
        items: [
            {
                title: "ProString Machine",
                classes: "ml-2 mr-4 custom-logo",
                brand: true,
                path: "/admin/menu",
                exact: false,
                img: './assets/images/logo/medium/dark.png'
            },
            {
                title: "Inicio",
                classes: "mx-2 custom-border-bottom-navbar-item",
                brand: false,
                path: "/admin/menu",
                exact: true
            },
            {
                title: "Encordadoras",
                classes: "mx-2 custom-border-bottom-navbar-item",
                brand: false,
                path: "/admin/menu/encordadoras",
                exact: false
            },
            {
                title: "Repuestos",
                classes: "mx-2 custom-border-bottom-navbar-item",
                brand: false,
                path: "/admin/menu/repuestos",
                exact: false
            },
            {
                title: "Servicios",
                classes: "mx-2 custom-border-bottom-navbar-item",
                brand: false,
                path: "/admin/menu/servicios",
                exact: false
            },
            {
                title: "Nosotros",
                classes: "mx-2 custom-border-bottom-navbar-item",
                brand: false,
                path: "/admin/menu/nosotros",
                exact: false
            },
            {
                title: "Contacto",
                classes: "mx-2",
                brand: false,
                path: "/admin/menu/contacto",
                exact: true
            }
        ]
    }
};

export const footer =
{
    listItems: [
        {
            title: "Productos y servicios",
            list: [{
                path: "/admin/menu/encordadoras",
                name: "Encordadoras"
            }, {
                path: "/admin/menu/repuestos",
                name: "Repuestos"
            }, {
                path: "/admin/menu/servicios",
                name: "Servicios"
            }]
        },
        {
            title: "ProString Machine",
            list: [{
                path: "/admin/menu/nosotros",
                name: "Sobre Nosotros"
            }, {
                path: "/admin/menu/contacto",
                name: "Contacto"
            }]
        }
    ],
    copyright: "Copyright © " + (new Date()).getFullYear() + " ProString Machine.",
    developBy: "Diseñado y desarrollado por",
    brand: { text: "ARdevs", href: "/", target: "_blank" }
};

export const routePages = [
    {
        id: 1,
        path: '/admin/menu/encordadoras',
        cmp: "Encordadoras",
        exact: false
    },
    {
        id: 2,
        path: '/admin/menu/repuestos',
        cmp: "Repuestos",
        exact: false
    },
    {
        id: 3,
        path: '/admin/menu/servicios',
        cmp: "Servicios",
        exact: false
    },
    {
        id: 4,
        path: '/admin/menu/contacto',
        cmp: "Contacto",
        exact: false
    },
    {
        id: 5,
        path: '/admin/menu/nosotros',
        cmp: "Nosotros",
        exact: false
    },
    {
        id: 6,
        path: '/admin/menu',
        cmp: "Inicio",
        exact: true
    },
    {
        id: 7,
        path: '/admin/menu',
        cmp: "Error404",
        exact: false,
        error404: true
    },
];