import { FETCH_DATA, PUT_DATA, DELETE_PRODUCT, UPDATE_PRODUCT, UPDATE_CONTACT, UPDATE_ABOUT, UPDATE_PRODUCT_FEATURE } from '../actions/data';

const initialState = {};


const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATA:
            return {
                ...action.payload
            };
        case PUT_DATA:
            return {
                ...action.payload
            };
        case DELETE_PRODUCT:
            const deletedProducts = { ...state.products };
            deletedProducts.products[action.payload.indexTypeProduct].productsList[action.payload.indexProduct] = { ...action.payload.updatedProduct };
            return {
                ...state, products: deletedProducts
            };
        case UPDATE_PRODUCT:
            const updatedProducts = { ...state.products };
            updatedProducts.products[action.payload.indexTypeProduct].productsList[action.payload.indexProduct] = { ...action.payload.updatedProduct };
            return {
                ...state, products: updatedProducts
            };
        case UPDATE_CONTACT:
            const updatedContact = { ...state.generalProps };
            updatedContact.contactScreen.contactList[action.payload.indexItemContact] = { ...action.payload.updatedItem };
            return {
                ...state, generalProps: updatedContact
            };
        case UPDATE_ABOUT:
            const updatedAbout = { ...state.about };
            if (action.payload.indexItemToUpdate === false) {
                updatedAbout[action.payload.indexType] = action.payload.updatedItem;
            } else {
                updatedAbout[action.payload.indexType][action.payload.indexItemToUpdate] = { ...action.payload.updatedItem };
            }
            return {
                ...state, about: updatedAbout
            };
        case UPDATE_PRODUCT_FEATURE:
            const productsOld = { ...state.products };
            productsOld[action.payload.indexType][action.payload.indexItemToUpdate] = { ...action.payload.updatedItem };
            return {
                ...state, products: productsOld
            };
        default:
            return state;
    }
};

export default dataReducer;