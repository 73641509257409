import React from 'react';

import './ContactList.css';
import ContactListItem from '../ConctactListItem/ContactListItem';

const ContactList = (props) => {
    return (
        <ul className="custom-list">
            {props.list.map(i => <ContactListItem key={i.id} {...i}/>)}
        </ul>
    );
};

export default ContactList;