import React from 'react';

//Bootstrap
import { Col } from 'react-bootstrap';

const FooterLegalItem = (props) => {
    const classes = ["custom-text-muted"];
    if(props.left){
        classes.push("text-left");
    }else if(props.right) {
        classes.push("text-right");
    }
    return (
        <Col className={classes.join(' ')}>
            <p><small>{props.children}</small></p>
        </Col>
    );
};

export default FooterLegalItem;