import React from 'react';

import './ContactFormItem.css';

const ContactFormItem = (props) => {
    let classes = "form-group";
    if (props.admin) {
        classes = "input-group align-items-center d-flex";
    }
    return (
        <div className={classes}>
            {
                props.textarea ?
                    props.fromContact === true ?
                        <textarea
                            id={props.id}
                            name={props.id}
                            className="form-control custom-form-item px-3 py-4"
                            cols={props.cols}
                            rows={props.rows}
                            placeholder={props.placeholder}
                            //onChange={props.change}
                            ref={props.reference ? props.reference({ ...props.validationRegister }) : null}
                            //value={props.value}
                            autoComplete="off"></textarea>
                        :
                        <textarea
                            id={props.id}
                            name={props.id}
                            className="form-control custom-form-item px-3 py-4"
                            cols={props.cols}
                            rows={props.rows}
                            placeholder={props.placeholder}
                            onChange={props.change}
                            ref={props.reference ? props.reference({ ...props.validationRegister }) : null}
                            value={props.value}
                            autoComplete="off"></textarea>
                    :
                    props.type === "file" ?
                        <React.Fragment>
                            <div className="custom-file">
                                <input
                                    id={props.id}
                                    name={props.id}
                                    className="custom-file-input d-none"
                                    type={props.type}
                                    onChange={props.change}
                                    ref={props.reference ? props.reference({ ...props.validationRegister }) : null}
                                    autoComplete="off"
                                    accept={props.accept} />
                                <label className="custom-file-label custom-form-item custom-placeholder" htmlFor={props.id}>{props.value !== props.src ? props.value : "Cargar"}</label>
                            </div>
                        </React.Fragment>
                        :
                        props.type === "checkbox" ?
                            <div className="custom-control custom-checkbox align-items-center">
                                <input
                                    id={props.id}
                                    name={props.id}
                                    className="custom-control-input"
                                    type={props.type}
                                    value={props.value}
                                    onChange={props.change}
                                    ref={props.reference ? props.reference() : null}
                                    defaultChecked={props.checked} />
                                <label className="custom-control-label" htmlFor={props.id}></label>
                            </div>
                            :
                            props.type === "colors" ?
                                props.checkboxs.map(c => <div key={c.id} className="custom-control custom-checkbox align-items-center mr-5">
                                    <input
                                        id={c.id}
                                        name={c.id}
                                        className="custom-control-input"
                                        type="checkbox"
                                        value={c.value}
                                        onChange={c.change}
                                        ref={props.reference ? props.reference() : null}
                                        defaultChecked={c.checked} />
                                    <label className="custom-control-label custom-placeholder" htmlFor={c.id}>{c.id}</label>
                                </div>)
                                :
                                props.fromContact === true ?
                                    <input
                                        id={props.id}
                                        name={props.id}
                                        className="form-control custom-form-item px-3 py-4"
                                        type={props.type}
                                        //value={props.value}
                                        //onChange={props.change}
                                        placeholder={props.placeholder}
                                        ref={props.reference ? props.reference({ ...props.validationRegister }) : null}
                                        autoComplete="off" />
                                    :
                                    <input
                                        id={props.id}
                                        name={props.id}
                                        className="form-control custom-form-item px-3 py-4"
                                        type={props.type}
                                        value={props.value}
                                        onChange={props.change}
                                        placeholder={props.placeholder}
                                        ref={props.reference ? props.reference({ ...props.validationRegister }) : null}
                                        autoComplete="off" />

            }
            {props.errorMessage ? <span className="custom-error-message">{props.errorMessage}</span> : null}
            {props.button ? props.button : null}
        </div>
    );
};

export default ContactFormItem;