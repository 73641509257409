import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

//Bootstrap
import { Nav, Navbar } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import './CustomNavbar.css'
import NavItem from './NavItem/NavItem';
// import * as dataNavbar from '../../data/Navbar';

const classes = "text-uppercase text-white custom-letter-spacing custom-nav custom-font-14 fixed-top";
const classesBgTransparent = `${classes} custom-bg-transparent custom-top-20`;
const classesBgWhite = `${classes} custom-bg-white`;

const variantDark = "dark";
const variantLight = "light";

const CustomNavbar = (props) => {
    const [iconMenu, setIconMenu] = useState(true);
    const [bgTransparent, setBgTransparent] = useState(props.initSection ? true : false);
    const [openMenu, setOpenMenu] = useState(false);
    const pathImgAdmin = props.location.pathname.indexOf("admin") === -1 ? "" : "../../";

    const handleBgTransparent = useCallback(() => {
        if (props.initSection && !openMenu) {
            if (document.documentElement.scrollTop >= 140) {
                setBgTransparent(false);
            } else {
                setBgTransparent(true);
            }
        }
    }, [props.initSection, openMenu]);

    useEffect(() => {
        window.addEventListener('scroll', handleBgTransparent, true);
        return (() => {
            window.removeEventListener('scroll', handleBgTransparent, true);
        });
    }, [handleBgTransparent]);

    const handleIconMenu = useCallback(() => {
        setIconMenu(!iconMenu);
        if ((!openMenu && bgTransparent) || (openMenu && document.documentElement.scrollTop < 140 && props.initSection)) {
            setBgTransparent(!bgTransparent);
        }
        setOpenMenu(!openMenu);
    }, [iconMenu, bgTransparent, openMenu, props.initSection]);

    return (
        <Navbar
            expand="xl"
            className={props.initSection && bgTransparent ? classesBgTransparent : classesBgWhite}
            variant={bgTransparent ? variantDark : variantLight}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleIconMenu} className={props.initSection && bgTransparent ? "text-white border-white" : ""}>
                <FontAwesomeIcon icon={iconMenu ? faBars : faTimes} /></Navbar.Toggle>
            <NavItem
                classes={props.dataNavbar.listItems.brandMobile.classes}
                path={props.dataNavbar.listItems.brandMobile.path}
                title={props.dataNavbar.listItems.brandMobile.title}
                brand={props.dataNavbar.listItems.brandMobile.brand}
                exact={props.dataNavbar.listItems.brandMobile.exact}
                img={`${pathImgAdmin}${props.dataNavbar.listItems.brandMobile.img}`}
                mobile={true}
                initSection={props.initSection}
            />
            {props.dataNavbar.listItems.cartMobile ? <Nav>
                <NavItem
                    classes={props.dataNavbar.listItems.cartMobile.classes}
                    path={props.dataNavbar.listItems.cartMobile.path}
                    title={props.dataNavbar.listItems.cartMobile.title}
                    brand={props.dataNavbar.listItems.cartMobile.brand}
                    exact={props.dataNavbar.listItems.cartMobile.exact}
                />
            </Nav> : null}
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav className="my-3 mx-5 m-xl-1 align-items-xl-center">
                    {props.dataNavbar.listItems.items.map((item) =>
                        <NavItem
                            key={item.title}
                            {...item}
                            // classes={item.classes}
                            // path={item.path}
                            // title={item.title}
                            // brand={item.brand}
                            // exact={item.exact}
                            img={item.img ? `${pathImgAdmin}${item.img}` : null}
                            initSection={props.initSection}
                        />
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default withRouter(CustomNavbar);