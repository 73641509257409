import React from 'react';

//Redux
import { useSelector } from 'react-redux';

import ProductType from '../../../components/Products/ProductType/ProductType';
// import { products, ID_SERVICIOS } from '../../../data/Products';

const Servicios = () => {
    const products = useSelector(state => state.data.products.products);
    const ID_SERVICIOS = useSelector(state => state.data.products.ID_SERVICIOS);

    return (
        <React.Fragment>
            {products.filter(p => p.id === ID_SERVICIOS).map(p => <ProductType key={p.id} {...p} fromHome={false} />)}
        </React.Fragment>
    );
};

export const componentName = "Servicios";
export default Servicios;