import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
import { authenticate, refreshToken, initialize } from '../../../store/actions/auth';

import './Admin.css';

const Admin = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const tryLogin = async () => {
            const userData = await localStorage.getItem('userData');
            if (!userData) {
                props.history.push("/admin/login");
                return;
            }
            const transformedData = JSON.parse(userData);
            const { token, userId, expiryDate, refreshTokenId } = transformedData;
            const expirationDate = new Date(expiryDate);
            const expirationTime = expirationDate.getTime() - new Date().getTime();

            if (expirationDate <= new Date() || !token || !userId || !refreshTokenId) {
                if (expirationDate <= new Date() && token && userId && refreshTokenId) {
                    await dispatch(initialize(userId, token, refreshTokenId));
                    await dispatch(refreshToken());
                    props.history.push("/admin/menu");
                } else {
                    props.history.push("/admin/login");
                }
                return;
            }

            await dispatch(authenticate(userId, token, expirationTime, refreshTokenId));
            props.history.push("/admin/menu");
        };

        tryLogin();
    }, [dispatch, props.history]);

    return (
        <div className="custom-bg-loader">
            <div className="custom-loader">Cargando...</div>
        </div>
    );
};

export default withRouter(Admin);