import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

import ProductType from '../../../../../components/Products/ProductType/Admin/ProductType';
import ProductDetail from '../../../../../components/Products/ProductDetail/Admin/ProductDetail';
// import { products, ID_MAQUINAS } from '../../../data/Products';

const Encordadoras = (props) => {
    const products = useSelector(state => state.data.products.products);
    const ID_MAQUINAS = useSelector(state => state.data.products.ID_MAQUINAS);
    const [productDetail, setProductDetail] = useState(null);

    useEffect(() => {
        if (props.location.state && props.location.state.id) {
            const productType = products.filter(p => p.id === ID_MAQUINAS);
            const productsList = productType[0].productsList;
            setProductDetail({ ...productsList.filter(p => p.id === props.location.state.id)[0], idType: ID_MAQUINAS });
        } else {
            setProductDetail(null);
        }
    }, [props.location.state, products, ID_MAQUINAS]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [productDetail]);

    return (
        <React.Fragment>
            {productDetail ?
                <ProductDetail {...productDetail} />
                :
                products.filter(p => p.id === ID_MAQUINAS).map(p => <ProductType key={p.id} {...p} />)
            }
        </React.Fragment>
    );
};

export const componentName = "Encordadoras";
export default withRouter(Encordadoras);