import React from 'react';

//Bootstrap
import { Modal } from 'react-bootstrap';

import './CustomModal.css';

const CustomModal = (props) => {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-uppercase" style={{ fontWeight: '200', letterSpacing: '.2rem' }}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontWeight: '200' }}>
                {props.body}
            </Modal.Body>
            {props.showFooter ?
                <Modal.Footer>
                    {props.primaryButton.show ? <a href={props.primaryButton.href} className="btn custom-btn" onClick={props.primaryButton.click}>{props.primaryButton.text}</a> : null}
                    {props.secondaryButton.show ? <a href={props.secondaryButton.href} className="btn custom-btn-dark" onClick={props.secondaryButton.click}>{props.secondaryButton.text}</a> : null}
                </Modal.Footer>
                : null}
        </Modal>
    );
};

export default CustomModal;