import React from 'react';
import {NavLink} from 'react-router-dom';

//Bootstrap
import { Col } from 'react-bootstrap';
import './FooterListItem.css'

const FooterListItem = (props) => {
    return (
        <Col md="3" className="pt-5 pb-4">
            <h6>{props.title}</h6>
            <ul className="list-unstyled">
                {props.list.map(item =>
                        <li key={item.name}><NavLink to={{ pathname: item.path }} className="btn-link custom-link">{item.name}</NavLink></li>
                    )
                }
            </ul>
        </Col>
    );
};

export default FooterListItem;