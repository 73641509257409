import React from 'react';

import { Link } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

import './Error404.css';

const url = `${window.location.origin}/assets/images/404.png`;

const Error404 = () => {
    return (
        <Container fluid>
            <Row className="p-sm-5 p-4 justify-content-center align-items-center">
                <Col md="6" lg="5" xl="4">
                    <img src={url} className="img-fluid" alt="Error 404" />
                </Col>
                <Col md="6" className="text-white text-center">
                    <h1 className="custom-title-404">404</h1>
                    <h3 className="text-uppercase custom-subtitle-404 mt-2">Error</h3>
                    <h5 className="custom-description-404 mt-4 mb-3">Página no encontrada</h5>
                    <Link to={{ pathname: "/" }}><button className="btn custom-btn px-4 py-3 my-3">Inicio</button></Link>
                </Col>
            </Row>
        </Container>
    );
};

export const componentName = "Error404";
export default Error404;