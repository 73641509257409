import React from 'react';

//Bootstrap
import { Col, Row } from 'react-bootstrap';

import CountUp from 'react-countup';
import { PieChart } from 'react-minimal-pie-chart';
import TitleLightBold from '../TitleLightBold/TitleLightBold';
import './CountChart.css';

const CountChart = (props) => {
    const classes = ["justify-content-center", "align-items-center"];
    if (props.visible) {
        classes.push("visible");
    } else {
        classes.push("visible-hidden");
    }

    return (
        <Row className={classes.join(' ')}>
            <Col xs="auto" className="mt-5 pt-5 text-center align-items-center justify-content-center small-screen-chart">
                <div className="custom-counter-container">
                    <PieChart
                        data={[
                            { value: props.initCounter.qty, color: 'url(#gradient1)' }
                        ]}
                        rounded={true}
                        animate={true}
                        animationDuration={4000}
                        lineWidth={25}
                        totalValue={props.initCounter.qty}
                    >
                        <defs>
                            <linearGradient id="gradient1">
                                <stop offset="0%" stopColor="#27719e" />
                                <stop offset="30%" stopColor="#1b618e" />
                                <stop offset="80%" stopColor="#11517d" />
                                <stop offset="100%" stopColor="#03375e" />
                            </linearGradient>
                        </defs>
                    </PieChart>
                </div>
                <CountUp {...(props.initCounter.plus ? {suffix:"+"} : null)} end={props.initCounter.qty} duration={4} className="custom-count-down" style={{ color: '#fff' }} />
            </Col>
            <Col xs="auto" className="text-white custom-count-label small-screen-label-chart">
                <TitleLightBold textLight={props.initCounter.textLight} textBold={props.initCounter.textBold} titleType="h3" />
            </Col>
        </Row>
    );
};

export default CountChart;