import React, { useCallback, useEffect, useState } from 'react';

//Bootstrap
import { Col, Row } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './ProductItem.css';

const heightWindow = window.innerHeight;

const ProductItem = (props) => {
    const [visible, setVisible] = useState(false);

    const handleVisible = useCallback(() => {
        if (visible === false) {
            const posTopElement = document.getElementById(props.id).getBoundingClientRect().y;
            const posBottomElement = posTopElement + (document.getElementById(props.id).getBoundingClientRect().height / 5);
            if ((heightWindow) > (posBottomElement)) {
                setVisible(true);
                window.removeEventListener('scroll', handleVisible, true);
            }
        }
    }, [props.id, visible]);

    useEffect(() => {
        setVisible(heightWindow > (document.getElementById(props.id).getBoundingClientRect().y + (document.getElementById(props.id).getBoundingClientRect().height / 5)));
    }, [props.id]);

    useEffect(() => {
        window.addEventListener('scroll', handleVisible, true);
        return (() => {
            window.removeEventListener('scroll', handleVisible, true);
        });
    }, [handleVisible]);

    return (
        <React.Fragment>
            {
                !props.fromHome ? (<React.Fragment><Row id={props.id} name={props.id} className={visible ? "custom-visible justify-content-center align-items-center my-4" : "custom-visible-hidden justify-content-center align-items-center my-4"}>
                    {props.withImage ? (<Col sm="9" md="6" lg="4">
                        <div className="my-2">
                            <div className="overflow-hidden">
                                <div style={{ backgroundColor: '#27719e' }} className={visible ? "custom-transition-background" : ""}>
                                    <div className="img-scale">
                                        <Link to={{ pathname: props.pathname, state: { id: props.id } }}>
                                            <img src={props.img} alt={props.alt} className={visible ? "img-fluid custom-position-img custom-transition-img" : "img-fluid"} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>) : null}
                    <Col sm="9" md="6">
                        <div className={visible ? "my-2 custom-card-service custom-transition-scale" : "my-2 custom-card-service"}>
                            {props.id ? <span className="custom-text-id font-italic">{props.id}</span> : null}
                            {props.withImage ? (<h3 className="mb-3">
                                <Link to={{ pathname: props.pathname, state: { id: props.id } }} className="custom-card-service-title">{props.title}</Link>
                            </h3>) : <h3 className="custom-card-service-title">{props.title}</h3>}
                            {!props.price || props.price === 0 ?
                                <span className="custom-price-card my-2">Consultar precio</span>
                                :
                                <span className="custom-price-card my-2">$ {props.price}</span>}
                            <p className="custom-card-service-description">{props.description}</p>
                            {props.withImage ? (<div className="custom-button-details">
                                <NavLink to={{ pathname: props.pathname, state: { id: props.id } }} className="custom-detail">
                                    Detalles
                                    <span className="ml-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                </NavLink>
                            </div>) : null}
                        </div>
                    </Col>
                </Row>
                    {/* {!props.lastItem ? <hr className="custom-hr" /> : null} */}
                </React.Fragment>) : (
                        props.withImage ? (<Col {...props.flex} id={props.id} name={props.id} className={visible ? "custom-visible" : "custom-visible-hidden"}>
                            <div className="mx-2 my-4">
                                <div className="overflow-hidden">
                                    <div style={{ backgroundColor: '#27719e' }} className={visible ? "custom-transition-background" : ""}>
                                        <div className="img-scale">
                                            <Link to={{ pathname: props.pathname, state: { id: props.id } }}>
                                                <img src={props.img} alt={props.alt} className={visible ? "img-fluid custom-position-img custom-transition-img" : "img-fluid"} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center pt-3">
                                    <h4 className="custom-label-img">{props.title}</h4>
                                    <div className="custom-button-details">
                                        <NavLink to={{ pathname: props.pathname, state: { id: props.id } }} className="custom-detail">
                                            Detalles
                                    <span className="ml-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Col>) : (<Col {...props.flex} id={props.id} name={props.id} className={visible ? "custom-visible" : "custom-visible-hidden"}>
                            <div className={visible ? "mb-4 mx-sm-4 custom-card-service custom-transition-scale" : "mb-4 mx-sm-4 custom-card-service"}>
                                <h3 className="custom-card-service-title">{props.title}</h3>
                                <p className="custom-card-service-description">{props.description}</p>
                            </div>
                        </Col>)
                    )
            }
        </React.Fragment>
    );
};

export default ProductItem;