import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

import ProductType from '../../../components/Products/ProductType/ProductType';
import ProductDetail from '../../../components/Products/ProductDetail/ProductDetail';
// import { products, ID_REPUESTOS } from '../../../data/Products';

const Repuestos = (props) => {
    const products = useSelector(state => state.data.products.products);
    const ID_REPUESTOS = useSelector(state => state.data.products.ID_REPUESTOS);
    const [productDetail, setProductDetail] = useState(null);

    useEffect(() => {
        if (props.location.state && props.location.state.id) {
            const productType = products.filter(p => p.id === ID_REPUESTOS);
            const productsList = productType[0].productsList;
            setProductDetail({ ...productsList.filter(p => p.id === props.location.state.id)[0] });
        } else {
            setProductDetail(null);
        }
    }, [props.location.state, products, ID_REPUESTOS]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [productDetail]);

    return (
        <React.Fragment>
            {productDetail ?
                <ProductDetail {...productDetail} />
                :
                products.filter(p => p.id === ID_REPUESTOS).map(p => <ProductType key={p.id} {...p} fromHome={false} />)
            }
        </React.Fragment>
    );
};

export const componentName = "Repuestos";
export default withRouter(Repuestos);