import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";

//Redux
import { useDispatch } from 'react-redux';
import { signIn } from '../../../../store/actions/auth';

//Bootstrap
import { Container, Row, Col, Form } from 'react-bootstrap';

import './SignIn.css';
import SignInItem from './SignInItem/SignInItem';
import { signInScreen } from "../../../../data/GeneralProps";
// import AdminLogged from '../AdminLogged/AdminLogged';

const SignIn = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAuth, setIsAuth] = useState(false);

    // const onSubmit = data => console.log("Enviando mail...", data);

    const onSubmit = useCallback(async (data) => {
        setError(null);
        setLoading(true);
        setIsAuth(false);
        try {
            await dispatch(signIn(data.email, data.password));
            setLoading(false);
            setIsAuth(true);
        } catch (err) {
            setError(err.message);
            setLoading(false);
            setIsAuth(false);
        }
    }, [dispatch]);

    if(isAuth){
        props.history.replace("/admin/menu");
    }

    return (
        // <React.Fragment>
        //     {isAuth ? <AdminLogged /> :
                <Container fluid className="custom-bg-black custom-h-100 p-sm-5 p-4">
                    < Row className="justify-content-center align-items-center p-md-5 p-3" >
                        <Col md="9" lg="6" xl="6" className="justify-content-center align-items-center custom-sign-form rounded p-sm-5 px-4 py-5">
                            {/* <Row> */}
                            {/* <Col md="6" className="custom-bg-image rounded-left" style={{backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, .75) 10%, rgba(39, 113, 158, 0)), url(./assets/images/b&w.jpg)'}}>
                        </Col>
                        <Col md="6">
                        </Col> */}
                            {/* <Col md="6" className="pl-sm-5"> */}
                            <h3 className="custom-header-login text-uppercase text-center pb-4 my-4">Login</h3>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                {/* <Form.Group>
                                    <Form.Label className="font-weight-lighter mb-0 custom-color-label">Usuario</Form.Label>
                                    <Form.Control type="email" className="custom-input-form" autoComplete="off"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="font-weight-lighter mb-0 custom-color-label">Contraseña</Form.Label>
                                    <Form.Control type="password" className="custom-input-form" autoComplete="off"/>
                                </Form.Group> */}
                                {signInScreen.contactForm.map(c => {
                                    let errorMessage = null;
                                    for (const k in errors) {
                                        if (k === c.id) {
                                            errorMessage = errors[k].message ? errors[k].message : null;
                                        }
                                    }
                                    return (
                                        <React.Fragment key={c.id}>
                                            <SignInItem {...c} reference={register} errorMessage={errorMessage} />
                                        </React.Fragment>
                                    )
                                })}
                                <div className="text-center pt-4 my-4">
                                    <button type="submit" className="btn custom-btn px-3 py-2" {...(loading ? { disabled: true } : { disabled: false })}>
                                        {loading ? <div className="spinner">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div> : "Ingresar"}
                                    </button>
                                    <span className="custom-error-message d-block mt-4">{error ? error : null}</span>
                                </div>
                            </Form>
                            {/* </Col>
                    </Row> */}
                        </Col>
                    </Row >
                </Container >
        //     }
        // </React.Fragment>
    );
};

export default withRouter(SignIn);