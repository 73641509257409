export const listItems = [
    {
        title: "Productos y servicios",
        list: [{
            path: "/encordadoras",
            name: "Encordadoras"
        }, {
            path: "/repuestos",
            name: "Repuestos"
        }, {
            path: "/servicios",
            name: "Servicios"
        }]
    },
    {
        title: "Compras",
        list: [{
            path: "/checkout",
            name: "Carrito"
        }]
    },
    {
        title: "ProString Machine",
        list: [{
            path: "/nosotros",
            name: "Sobre Nosotros"
        }, {
            path: "/contacto",
            name: "Contacto"
        }]
    }
];
export const copyright = "Copyright © " + (new Date()).getFullYear() + " ProString Machine.";
export const developBy = "Diseñado y desarrollado por";
export const brand = { text: "ARdevs", href: "/", target: "_blank"};