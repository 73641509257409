import React from 'react';

import './AuxiliarComponent.css';

const AuxiliarComponent = (props) => {
    const classes = ["custom-bg-black"];
    
    if (!props.initSection) {
        classes.push("custom-top-section");
    }
    
    return (
        <div className={classes.join(' ')}>
            {props.children}
        </div>
    )
};

export default AuxiliarComponent;