import * as about from '../../data/About';
import * as footer from '../../data/Footer';
import * as generalProps from '../../data/GeneralProps';
import * as navbar from '../../data/Navbar';
import * as products from '../../data/Products';
import * as admin from '../../data/Admin';

export const FETCH_DATA = 'FETCH_DATA';
export const PUT_DATA = 'PUT_DATA';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const UPDATE_PRODUCT_FEATURE = 'UPDATE_PRODUCT_FEATURE';

export const fetchData = () => {
    return async dispatch => {
        try {
            const response = await fetch(
                'https://prostringmachine.firebaseio.com/data.json?',
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            const resData = await response.json();
            const loadedData = { ...resData }

            dispatch({ type: FETCH_DATA, payload: loadedData });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};

export const putData = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                'https://prostringmachine.firebaseio.com/data.json?',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    name: 'data',
                    body: JSON.stringify({
                        about: {
                            ...about
                        },
                        footer: {
                            ...footer
                        },
                        generalProps: {
                            ...generalProps
                        },
                        navbar: {
                            ...navbar
                        },
                        products: {
                            ...products
                        },
                        admin: {
                            ...admin
                        }
                    })
                }
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            const resData = await response.json();

            dispatch({
                type: PUT_DATA, payload: resData
            });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};

export const switchProduct = (id, idType, inactive) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const products = getState().data.products.products;
        // const typeProduct = products.filter(p => p.id === idType);
        // const product = typeProduct[0].productsList.filter(p => p.id === id);
        // const updatedProduct = {...product[0], deleted: true};
        const indexTypeProduct = products.findIndex(p => p.id === idType);
        const typeProduct = products[indexTypeProduct];
        const indexProduct = typeProduct.productsList.findIndex(p => p.id === id);
        const product = typeProduct.productsList[indexProduct];
        const updatedProduct = { ...product, inactive: inactive };
        try {
            const response = await fetch(`https://prostringmachine.firebaseio.com/data/products/products/${indexTypeProduct}/productsList/${indexProduct}.json?auth=${token}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedProduct)
                }
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            // const resData = await response.json();
            // const loadedData = { ...resData }
            // console.log(loadedData);
            dispatch({ type: DELETE_PRODUCT, payload: { indexTypeProduct: indexTypeProduct, indexProduct: indexProduct, updatedProduct: updatedProduct } });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};

export const updateProduct = (props, item) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const products = getState().data.products.products;
        const indexTypeProduct = products.findIndex(p => p.id === props.idType);
        const typeProduct = products[indexTypeProduct];
        const indexProduct = typeProduct.productsList.findIndex(p => p.id === props.id);
        const product = typeProduct.productsList[indexProduct];
        let updatedProduct = { ...product };
        switch (item.id) {
            case "title":
                updatedProduct.title = item.value;
                updatedProduct.alt = item.value;
                break;
            case "price":
                updatedProduct.price = parseInt(item.value);
                break;
            case "description":
                updatedProduct.description = item.value;
                break;
            case "outBox":
                updatedProduct.tableDetails.measures.outBox = item.value;
                break;
            case "inBox":
                updatedProduct.tableDetails.measures.inBox = item.value;
                break;
            case "guarantee":
                updatedProduct.tableDetails.guarantee = item.value;
                break;
            case "pinzas":
                updatedProduct.tableDetails.pinzas = item.checked;
                break;
            case "extras":
                updatedProduct.tableDetails.extras = item.value;
                break;
            case "colorsAvailable":
                const newColors = [];
                for (let i = 0; i < item.checkboxs.length; i++) {
                    const element = item.checkboxs[i];
                    if (element.checked) {
                        newColors.push(element.id);
                    }
                }
                updatedProduct.tableDetails.colorsAvailable = [...newColors];
                break;
            case "imgDetails1":
                updatedProduct.img = item.value;
                updatedProduct.imgDetails[0] = item.value;
                break;
            case "imgDetails2":
                if (updatedProduct.imgDetails[1]) {
                    updatedProduct.imgDetails[1] = item.value;
                } else {
                    updatedProduct.imgDetails.push(item.value);
                }
                break;
            case "imgDetails3":
                if (updatedProduct.imgDetails[2]) {
                    updatedProduct.imgDetails[2] = item.value;
                } else {
                    updatedProduct.imgDetails.push(item.value);
                }
                break;
            case "imgDetails4":
                if (updatedProduct.imgDetails[3]) {
                    updatedProduct.imgDetails[3] = item.value;
                } else {
                    updatedProduct.imgDetails.push(item.value);
                }
                break;
            default:
                break;
        }
        try {
            const response = await fetch(`https://prostringmachine.firebaseio.com/data/products/products/${indexTypeProduct}/productsList/${indexProduct}.json?auth=${token}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedProduct)
                }
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            const resData = await response.json();
            const loadedData = { ...resData }
            dispatch({ type: UPDATE_PRODUCT, payload: { indexTypeProduct: indexTypeProduct, indexProduct: indexProduct, updatedProduct: loadedData } });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};

export const updateContact = (item) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const contactList = getState().data.generalProps.contactScreen.contactList;
        const indexItemContact = contactList.findIndex(p => p.id === item.idItem);
        const itemContact = contactList[indexItemContact];
        itemContact.value[item.indexValue] = item.value;
        let updatedItem = { ...itemContact };
        try {
            const response = await fetch(`https://prostringmachine.firebaseio.com/data/generalProps/contactScreen/contactList/${indexItemContact}.json?auth=${token}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedItem)
                }
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            const resData = await response.json();
            const loadedData = { ...resData }
            dispatch({ type: UPDATE_CONTACT, payload: { indexItemContact: indexItemContact, updatedItem: loadedData } });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};

export const updateAboutUs = (item) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const about = getState().data.about;
        const productsFeatures = getState().data.products.productsFeatures;
        let indexType = null;
        let dataType = null;
        for (const key in about) {
            const element = about[key];
            if (item.idType === key) {
                indexType = key;
                dataType = element;
            }
        }
        if (indexType === null && dataType === null) {
            if (item.idType === "productsFeatures") {
                indexType = "productsFeatures";
                dataType = productsFeatures;
            }
        }
        let itemToUpdate = null;
        let updatedItem = null;
        let url = null;
        let DISPATCH_TYPE = null;
        let indexItemToUpdate = false;
        if (Array.isArray(dataType)) {
            indexItemToUpdate = dataType.findIndex(p => p.id === item.idItem);
            itemToUpdate = dataType[indexItemToUpdate];
            if (item.idType === "countUps") {
                itemToUpdate.qty = parseInt(item.value);
            } else {
                itemToUpdate.description = item.value;
            }
            updatedItem = { ...itemToUpdate };
            url = `https://prostringmachine.firebaseio.com/data/about/${indexType}/${indexItemToUpdate}.json?auth=${token}`;
            DISPATCH_TYPE = UPDATE_ABOUT;
            if (item.idType === "productsFeatures") {
                url = `https://prostringmachine.firebaseio.com/data/products/${indexType}/${indexItemToUpdate}.json?auth=${token}`;
                DISPATCH_TYPE = UPDATE_PRODUCT_FEATURE;
            }
        } else {
            itemToUpdate = item.value;
            updatedItem = itemToUpdate;
            url = `https://prostringmachine.firebaseio.com/data/about/${indexType}.json?auth=${token}`;
            DISPATCH_TYPE = UPDATE_ABOUT;
        }
        try {
            const response = await fetch(url,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedItem)
                }
            );

            if (!response.ok) {
                throw new Error('Ha ocurrido un error inesperado.');
            }

            // const resData = await response.json();
            // const loadedData = { ...resData }
            dispatch({ type: DISPATCH_TYPE, payload: { indexType: indexType, indexItemToUpdate: indexItemToUpdate, updatedItem: updatedItem } });
        } catch (err) {
            // send to custom analytics server
            throw err;
        }
    };
};
