import React from 'react';

//Bootstrap
import { Container, Row } from 'react-bootstrap';

import './CustomFooter.css';
import FooterListItem from './FooterListItem/FooterListItem';
import FooterLegalItem from './FooterLegal/FooterLegalItem';
// import * as dataFooter from '../../data/Footer';

const CustomFooter = (props) => {
    return (
        <Container fluid className="custom-bg-black-footer text-center text-white bd-footer justify-content-md-center">
            <Row className="justify-content-center">
                {props.dataFooter.listItems.map(item => <FooterListItem key={item.title} title={item.title} list={item.list} />)}
            </Row>
            <Row className="px-md-5 px-sm-3">
                <FooterLegalItem left>{props.dataFooter.copyright}</FooterLegalItem>
                <FooterLegalItem right>
                    {props.dataFooter.developBy} <a href={props.dataFooter.brand.href} target={props.dataFooter.brand.target} className="custom-text-muted">{props.dataFooter.brand.text}</a>
                </FooterLegalItem>
            </Row>
        </Container>
    );
};

export default CustomFooter;