import { faEye, faRocket, faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

// export const aboutHistory = [
//     {
//         id: 1,
//         description: "Nacimos en el año 2014 con el objetivo de cubrir las necesidades de jugadores tanto profesionales como amateurs, y mismo de coachs como profesores, notando una gran falta de eficiencia a la hora de realizar el mantenimiento de las cuerdas de sus raquetas."
//     },
//     {
//         id: 2,
//         description: "Es por esto que creamos los nuevos modelos <strong class=\"custom-strong-about\">PSM-M001</strong> y <strong class=\"custom-strong-about\">PSM-M002</strong> buscando una calidad suprema todavía no existente para el mercado de ese entonces. Nuestros productos tienen la capacidad de tener la mayor durabilidad y excelencia de la línea asegurando el perfecto equilibro."
//     },
//     {
//         id: 3,
//         description: "En <strong class=\"custom-strong-about\">ProString Machine</strong>, los productos son 100% artesanales, es decir, nos tomamos el trabajo de realizar un exahustivo control de calidad para cada uno de ellos y así evitar todo tipo de error."
//     },
//     {
//         id: 4,
//         description: "Dada la confianza que depositan en nostros todos nuestros clientes haciéndonos llegar todos sus afectos y recomendándonos incluso en otros deportes, en el año 2020 sacamos al mercado el nuevo e innovador modelo <strong class=\"custom-strong-about\">PSM-M003</strong>, apto para raquetas de squash y badminton logrando una mejor estabilidad para la misma."
//     }
// ]

//Importar en la primera linea los iconos
export const aboutPrinciples = [
    {
        id: 1,
        icon: faRocket,
        title: "Misión",
        description: "Otorgarle al jugador las mejores herramientas del mercado para ayudarlo a mejorar su eficiencia, precisión y rendimiento."
    },
    {
        id: 2,
        icon: faEye,
        title: "Visión",
        description: "Liderar el mercado internacional impulsando la innovación y robustez de nuestros productos, asegurando la durabilidad."
    },
    {
        id: 3,
        icon: faHandHoldingHeart,
        title: "Valores",
        description: "Excelencia, Innovación, Durabilidad, Liderazgo, Calidad, Compromiso, Humildad y Compromiso Ambiental."
    }
];

export const countUps = [
    {
        id: 1,
        qty: 300,
        plus: true,
        description: "Jugadores que confiaron en nosotros"
    },
    {
        id: 2,
        qty: 7,
        plus: true,
        description: "Años brindando el mejor producto del mercado"
    },
    {
        id: 3,
        qty: 3,
        plus: false,
        description: "Modelos diferentes de máquinas"
    },
    {
        id: 4,
        qty: 20,
        plus: true,
        description: "Repuestos de la mejor calidad"
    },
];

export const history = "<p>Nacimos en el año 2014 con el objetivo de cubrir las necesidades de jugadores tanto profesionales como amateurs, y mismo de coachs como profesores, notando una gran falta de eficiencia a la hora de realizar el mantenimiento de las cuerdas de sus raquetas.</p><p>&nbsp;</p><p>Es por esto que creamos los nuevos modelos <strong>PSM-M001</strong> y <strong>PSM-M002</strong> buscando una calidad suprema todavía no existente para el mercado de ese entonces. Nuestros productos tienen la capacidad de tener la mayor durabilidad y excelencia de la línea asegurando el perfecto equilibro.</p><p>&nbsp;</p><p>En <strong>ProString Machine</strong>, los productos son 100% artesanales, es decir, nos tomamos el trabajo de realizar un exhaustivo control de calidad para cada uno de ellos y así evitar todo tipo de error.</p><p>&nbsp;</p><p>Dada la confianza que depositan en nosotros todos nuestros clientes haciéndonos llegar todos sus afectos y recomendándonos incluso en otros deportes, en el año 2020 sacamos al mercado el nuevo e innovador modelo <strong>PSM-M003</strong>, apto para raquetas de squash y bádminton logrando una mejor estabilidad para la misma.</p>";