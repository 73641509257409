import React from 'react';

import './SignInItem.css';

const SignInItem = (props) => {
    return (
        <div className="form-group">
            <label className="font-weight-lighter mb-0 custom-color-label form-label" htmlFor={props.id}>{props.label}</label>
            <input
                id={props.id}
                name={props.id}
                className="form-control custom-input-form px-3 py-4"
                type={props.type}
                ref={props.reference({ ...props.validationRegister })}
                />
            {props.errorMessage ? <span className="custom-error-message">{props.errorMessage}</span> : null}
        </div>
    );
};

export default SignInItem;