import React, { useCallback } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

// import './IndexApp.css';
import AuxiliarComponent from '../../hoc/AuxiliarComponent/AuxiliarComponent';
import ScrollToTop from '../../hoc/ScrollToTop/ScrollToTop';
import InitSection from '../InitSection/InitSection';
import CustomNavbar from '../../components/Navbar/CustomNavbar';
import CustomFooter from '../../components/Footer/CustomFooter';

//Screens
import Checkout, { componentName as CheckoutName } from '../screens/Checkout/Checkout';
import Contacto, { componentName as ContactoName } from '../screens/Contacto/Contacto';
import Encordadoras, { componentName as EncordadorasName } from '../screens/Encordadoras/Encordadoras';
import Error404, { componentName as Error404Name } from '../screens/Error404/Error404';
import Inicio, { componentName as InicioName } from '../screens/Inicio/Inicio';
import Nosotros, { componentName as NosotrosName } from '../screens/Nosotros/Nosotros';
import Repuestos, { componentName as RepuestosName } from '../screens/Repuestos/Repuestos';
import Servicios, { componentName as ServiciosName } from '../screens/Servicios/Servicios';
const screensArr = [[Checkout, CheckoutName], [Contacto, ContactoName], [Encordadoras, EncordadorasName],
[Error404, Error404Name], [Inicio, InicioName], [Nosotros, NosotrosName], [Repuestos, RepuestosName],
[Servicios, ServiciosName]];

const IndexApp = (props) => {
    const dataNavbar = useSelector(state => state.data.navbar);
    const dataFooter = useSelector(state => state.data.footer);
    
    const handleNavbar = useCallback(() => {
        let i = 0;
        let renderNavbar = false;
        while (i < props.dataLoaded.generalProps.routePages.length && !renderNavbar) {
            const element = props.dataLoaded.generalProps.routePages[i];
            if (props.location.pathname === element.path) {
                if (element.initSection) {
                    return <InitSection {...element.initSection} />;
                } else {
                    return <CustomNavbar initSection={false} dataNavbar={dataNavbar}/>;
                }
            }
            i = i + 1;
        }
        return <CustomNavbar initSection={false} dataNavbar={dataNavbar}/>;
    }, [props.location.pathname, props.dataLoaded.generalProps.routePages, dataNavbar]);

    return (
        <React.Fragment>
            <ScrollToTop />
            {handleNavbar()}
            <Switch>
                {props.dataLoaded.generalProps.routePages.map(r => {
                    const Cmp = screensArr.find(s => s[1] === r.cmp)[0];
                    return (<Route
                        key={r.id}
                        path={r.path}
                        exact={r.exact}
                        render={() => <AuxiliarComponent initSection={r.initSection}><Cmp /></AuxiliarComponent>}
                    />)
                }
                )}
            </Switch>
            <CustomFooter dataFooter={dataFooter}/>
        </React.Fragment>
    );
}

export default withRouter(IndexApp);