import React from 'react';

import ProductItem from '../../ProductItem/Admin/ProductItem';

const ProductList = (props) => {
    return (
        <React.Fragment>
            {props.productsList.map((p, index, array) => !p.inactive && props.active ?
                <ProductItem
                    key={p.id}
                    idType={props.id}
                    {...p}
                    withImage={props.withImage}
                    flex={props.flex}
                    pathname={props.pathname} />
                : p.inactive && !props.active ?
                    <ProductItem
                        key={p.id}
                        idType={props.id}
                        {...p}
                        withImage={props.withImage}
                        flex={props.flex}
                        pathname={props.pathname} /> : null
            )
            }
        </React.Fragment>
    );
};

export default ProductList;