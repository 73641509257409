export const routePages = [
    {
        id: 1,
        path: '/encordadoras',
        cmp: "Encordadoras",
        initSection: false,
        exact: false
    },
    {
        id: 2,
        path: '/repuestos',
        cmp: "Repuestos",
        initSection: false,
        exact: false
    },
    {
        id: 3,
        path: '/servicios',
        cmp: "Servicios",
        initSection: false,
        exact: false
    },
    {
        id: 4,
        path: '/contacto',
        cmp: "Contacto",
        initSection: false,
        exact: false
    },
    {
        id: 5,
        path: '/checkout',
        cmp: "Checkout",
        initSection: false,
        exact: false
    },
    {
        id: 6,
        path: '/nosotros',
        cmp: "Nosotros",
        initSection: {
            textLight: "Sobre ",
            textBold: "Nosotros",
            boldDisplayBlock: false,
            background: "url(./assets/images/b&w.jpg)"
        },
        exact: false
    },
    {
        id: 7,
        path: '/',
        cmp: "Inicio",
        initSection: {
            textLight: "Somos ",
            textBold: "ProString Machine",
            boldDisplayBlock: true,
            background: "url(./assets/images/b&w.jpg)"
        },
        exact: true
    },
    {
        id: 8,
        path: '/',
        cmp: "Error404",
        initSection: false,
        exact: false,
        error404: true
    },
];

export const initCounter = {
    qty: 300,
    plus: true,
    textLight: "Jugadores que ",
    textBold: "confiaron en nosotros"
}

export const contactScreen = {
    contactForm: [
        { id: "name", type: "text", value: "", placeholder: "Nombre", change: null, validationRegister: { required: "Debe ingresar un nombre" } },
        {
            id: "email", type: "text", value: "", placeholder: "Email", change: null, validationRegister: {
                required: "Debe ingresar un email",
                pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: "Dirección de correo inválida" }
            }
        },
        {
            id: "phone", type: "number", value: "", placeholder: "Teléfono", change: null, validationRegister: { required: "Debe ingresar un teléfono" }
        },
        { id: "message", textarea: true, value: "", placeholder: "Mensaje", change: null, cols: "30", rows: "10", validationRegister: { required: "Debe ingresar un mensaje" } }
    ],
    contactList: [
        { id: "emailInfo", title: "Email", value: ["prostringmachine@hotmail.com"] },
        { id: "telInfo", title: "Teléfono", value: ["+54 911 5488-0837", "+54 911 4035-9347"] },
        { id: "locationInfo", title: "Ubicación", value: ["Ramos Mejía, Buenos Aires", "Argentina"] },
    ],
    socialButtons: [
        { id: "facebook", icon: "facebook-f", href: "https://www.facebook.com/maquinas.deencordar.psm" },
        { id: "whatsapp", icon: "whatsapp", href: "https://api.whatsapp.com/send?phone=5491154880837&text=Hola ProString Machine! Te estoy contactando por uno de tus productos en tu sitio web" },
        { id: "mail", icon: "envelope", href: "mailto:prostringmachine@hotmail.com?subject=Hola ProString Machine! Te estoy contactando por uno de tus productos en tu sitio web" }
    ]
}

export const signInScreen = {
    contactForm: [
        {
            id: "email", type: "text", label: "Email", validationRegister: {
                required: "Debe ingresar un email",
                pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: "Dirección de correo inválida" }
            }
        },
        { id: "password", type: "password", label: "Contraseña", validationRegister: { required: "Debe ingresar una contraseña" } }
    ]
}