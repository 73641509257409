import React, { useCallback, useEffect, useState } from 'react';

//Redux
import { useDispatch } from 'react-redux';

//Bootstrap
import { Col, Row } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import '../ProductItem.css';
import CustomModal from '../../../CustomModal/CustomModal';
import { switchProduct } from '../../../../store/actions/data';

// const heightWindow = window.innerHeight;

const ProductItem = (props) => {
    // const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [deletedOk, setDeletedOk] = useState(false);
    const [deletedFail, setDeletedFail] = useState(false);
    const [mounted, setMounted] = useState(false);
    const messageModalDesactivate = `¿Estás seguro de querer desactivar el producto ${props.title} (${props.id})? El mismo solo será dado de baja para los usuarios finales, podés verlo en la solapa de "INACTIVOS" y volverlo a activar cuando quieras.`;
    const messageModalActivate = `¿Estás seguro de querer activar el producto ${props.title} (${props.id})? El mismo será visble para todos los usuarios finales, podés verlo en la solapa de "ACTIVOS" y volverlo a desactivar cuando quieras.`;
    // const hrefDesactivate = null;

    // const handleVisible = useCallback(() => {
    //     if (visible === false) {
    //         const posTopElement = document.getElementById(props.id).getBoundingClientRect().y;
    //         const posBottomElement = posTopElement + (document.getElementById(props.id).getBoundingClientRect().height / 3);
    //         if ((heightWindow) > (posBottomElement)) {
    //             setVisible(true);
    //             window.removeEventListener('scroll', handleVisible, true);
    //         }
    //     }
    // }, [props.id, visible]);

    // useEffect(() => {
    //     setVisible(heightWindow > (document.getElementById(props.id).getBoundingClientRect().y + (document.getElementById(props.id).getBoundingClientRect().height / 3)));
    // }, [props.id]);

    // useEffect(() => {
    //     window.addEventListener('scroll', handleVisible, true);
    //     return (() => {
    //         window.removeEventListener('scroll', handleVisible, true);
    //     });
    // }, [handleVisible]);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    const deleteProductHandler = useCallback(() => {
        if (mounted) {
            //setModal(false);
            setError(null);
            setLoading(true);
            setDeletedOk(false);
            setDeletedFail(false);
            try {
                dispatch(switchProduct(props.id, props.idType, !props.inactive));
                setLoading(false);
                setDeletedOk(true);
            } catch (err) {
                setError(err.message);
                setLoading(false);
                setDeletedFail(true);
            }
        }
    }, [dispatch, props.id, props.idType, props.inactive, mounted]);

    return (
        <React.Fragment>
            <React.Fragment>
                {/* <Row id={props.id} name={props.id} className={visible ? "custom-visible justify-content-center align-items-center my-4" : "custom-visible-hidden justify-content-center align-items-center my-4"}> */}
                <Row id={props.id} name={props.id} className="custom-visible justify-content-center align-items-center my-4">
                    {props.withImage ? (<Col sm="9" md="6" lg="4">
                        <div className="my-2">
                            <div className="overflow-hidden">
                                {/* <div style={{ backgroundColor: '#27719e' }} className={visible ? "custom-transition-background" : ""}> */}
                                <div style={{ backgroundColor: '#27719e' }}>
                                    <div className="img-scale">
                                        <Link to={{ pathname: `/admin/menu${props.pathname}`, state: { id: props.id } }}>
                                            {/* <img src={props.img} alt={props.alt} className={visible ? "img-fluid custom-position-img custom-transition-img" : "img-fluid"} /> */}
                                            <img src={`../../../${props.img}`} alt={props.alt} className="img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>) : null}
                    <Col sm="9" md="6">
                        {/* <div className={visible ? "my-2 custom-card-service custom-transition-scale" : "my-2 custom-card-service"}> */}
                        <div className="my-2 custom-card-service">
                            {props.id ? <span className="custom-text-id font-italic">{props.id}</span> : null}
                            <h3 className="mb-3">
                                <Link to={{ pathname: `/admin/menu${props.pathname}`, state: { id: props.id } }} className="custom-card-service-title">{props.title}</Link>
                            </h3>
                            {!props.price || props.price === 0 ?
                                <span className="custom-price-card my-2">Consultar precio</span>
                                :
                                <span className="custom-price-card my-2">$ {props.price}</span>}
                            <p className="custom-card-service-description">{props.description}</p>
                            <div className="custom-button-details d-flex justify-content-between">
                                <NavLink to={{ pathname: `/admin/menu${props.pathname}`, state: { id: props.id } }} className="custom-detail">
                                    Editar
                                    <span className="ml-2"><FontAwesomeIcon icon={faChevronRight} /></span>
                                </NavLink>
                                {/* <a href={hrefDesactivate} onClick={() => setModal(true)} className="custom-detail font-weight-bolder">
                                    {!props.inactive ? "Desactivar" : "Activar"}
                                    <span className="ml-2"><FontAwesomeIcon icon={!props.inactive ? faTrash : faEye} /></span>
                                </a> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
            <CustomModal
                show={modal}
                onHide={setModal}
                title="Aviso"
                body={loading ? "Cargando..." :
                    deletedOk ? (!props.inactive ? "Producto desactivado correctamente!" : "Producto activado correctamente!") :
                        deletedFail ? error : (!props.inactive ? messageModalDesactivate : messageModalActivate)}
                showFooter={loading ? false : true}
                primaryButton={{ show: (loading ? false : true), href: null, click: deleteProductHandler, text: (deletedOk || deletedFail ? "Ok" : "Sí") }}
                secondaryButton={{ show: (loading || deletedOk || deletedFail ? false : true), href: null, click: () => setModal(false), text: "Cancelar" }}
            />
        </React.Fragment>
    );
};

export default ProductItem;