import React from 'react';

//Bootstrap
import { Container, Row } from 'react-bootstrap';

import ProductTitle from '../ProductTitle/ProductTitle';
import ProductList from '../ProductList/ProductList';
import './ProductType.css';
import { NavLink } from 'react-router-dom';

const ProductType = (props) => {
    return (
        <React.Fragment>
            {props.fromHome ? <hr className="custom-hr" /> : null}
            <Container fluid className="py-5">
                <ProductTitle textLight={props.textLight} textBold={props.textBold} titleType={props.fromHome ? "h3" : "h1"} />
                <Row className="justify-content-center align-items-center p-5">
                    <ProductList {...props} />
                </Row>
                {props.fromHome ? (<button className="btn custom-btn d-block m-auto px-4 py-2">
                    <NavLink to={{ pathname: props.pathname }} className="custom-btn-children">Ver más</NavLink>
                </button>) : null}
            </Container>
        </React.Fragment>
    );
};

export default ProductType;