import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { authenticate, refreshToken, initialize } from '../../../../store/actions/auth';

import './AdminLogged.css';
import CustomNavbar from '../../../../components/Navbar/CustomNavbar';
import CustomFooter from '../../../../components/Footer/CustomFooter';
import AuxiliarComponent from '../../../../hoc/AuxiliarComponent/AuxiliarComponent';
//Screens
import Contacto, { componentName as ContactoName } from '../screens/Contacto/Contacto';
import Encordadoras, { componentName as EncordadorasName } from '../screens/Encordadoras/Encordadoras';
import Error404, { componentName as Error404Name } from '../screens/Error404/Error404';
import Inicio, { componentName as InicioName } from '../screens/Inicio/Inicio';
import Nosotros, { componentName as NosotrosName } from '../screens/Nosotros/Nosotros';
import Repuestos, { componentName as RepuestosName } from '../screens/Repuestos/Repuestos';
import Servicios, { componentName as ServiciosName } from '../screens/Servicios/Servicios';
const screensArr = [[Contacto, ContactoName], [Encordadoras, EncordadorasName],
[Error404, Error404Name], [Inicio, InicioName], [Nosotros, NosotrosName], [Repuestos, RepuestosName],
[Servicios, ServiciosName]];


const AdminLogged = (props) => {
    const dataNavbar = useSelector(state => state.data.admin.navbar);
    const dataFooter = useSelector(state => state.data.admin.footer);
    const routePages = useSelector(state => state.data.admin.routePages);
    const dispatch = useDispatch();

    useEffect(() => {
        const tryLogin = async () => {
            const userData = await localStorage.getItem('userData');
            if (!userData) {
                props.history.push("/admin/login");
                return;
            }
            const transformedData = JSON.parse(userData);
            const { token, userId, expiryDate, refreshTokenId } = transformedData;
            const expirationDate = new Date(expiryDate);
            const expirationTime = expirationDate.getTime() - new Date().getTime();

            if (expirationDate <= new Date() || !token || !userId || !refreshTokenId) {
                if (expirationDate <= new Date() && token && userId && refreshTokenId) {
                    await dispatch(initialize(userId, token, refreshTokenId));
                    await dispatch(refreshToken());
                    // props.history.push("/admin/menu");
                } else {
                    props.history.push("/admin/login");
                }
                return;
            }

            await dispatch(authenticate(userId, token, expirationTime, refreshTokenId));
            // props.history.push("/admin/menu");
        };

        tryLogin();
    }, [dispatch, props.history]);
    return (
        <React.Fragment>
            <CustomNavbar dataNavbar={dataNavbar} />
            {/* <AuxiliarComponent><h1>Bienvenido Admin!</h1></AuxiliarComponent> */}
            <Switch>
                {routePages.map(r => {
                    const Cmp = screensArr.find(s => s[1] === r.cmp)[0];
                    return (<Route
                        key={r.id}
                        path={r.path}
                        exact={r.exact}
                        render={() => <AuxiliarComponent><Cmp /></AuxiliarComponent>}
                    />)
                }
                )}
            </Switch>
            <CustomFooter dataFooter={dataFooter} />
        </React.Fragment>
    );
};

export default withRouter(AdminLogged);