import React, { useState, useCallback } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// import './ProductDetail.css';
import ContactFormItem from '../../../../../components/Contact/ContactFormItem/ContactFormItem';
import ProductTitle from '../../../../../components/Products/ProductTitle/ProductTitle';
import { updateContact } from '../../../../../store/actions/data';

const Contacto = () => {
    const contactList = useSelector(state => state.data.generalProps.contactScreen.contactList);
    const lightId = `Información de `;
    const boldId = `Contacto`;
    const dispatch = useDispatch();

    const saveHandler = useCallback(async (item) => {
        if (item.required && item.value === "") {
            item.change(null, { errorMessage: "Este campo no puede estar vacío", success: false }, false);
            return;
        }
        if (item.email && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(item.value)) {
            item.change(null, { errorMessage: "Dirección de correo inválida", success: false }, false);
            return;
        }
        item.change(null, { errorMessage: "", success: false }, true);
        try {
            await dispatch(updateContact(item));
            item.change(null, { errorMessage: "Guardado correctamente", success: true }, false)
        } catch (err) {
            item.change(null, { errorMessage: err.message, success: false }, false)
        }
    }, [dispatch]);

    const [emailInfo, setEmailInfo] = useState({ loading: false, error: { errorMessage: "", success: false }, value: contactList[0].value[0] });
    const emailInfoHandler = (event, err, loading) => setEmailInfo({ value: (event ? event.target.value : emailInfo.value), error: (err ? err : emailInfo.err), loading: (loading ? loading : emailInfo.loading) });
    const [telInfo, setTelInfo] = useState({ loading: false, error: { errorMessage: "", success: false }, value: contactList[1].value[0] });
    const telInfoHandler = (event, err, loading) => setTelInfo({ value: (event ? event.target.value : telInfo.value), error: (err ? err : telInfo.err), loading: (loading ? loading : telInfo.loading) });
    const [locationInfo1, setLocationInfo1] = useState({ loading: false, error: { errorMessage: "", success: false }, value: contactList[2].value[0] });
    const locationInfo1Handler = (event, err, loading) => setLocationInfo1({ value: (event ? event.target.value : locationInfo1.value), error: (err ? err : locationInfo1.err), loading: (loading ? loading : locationInfo1.loading) });
    const [locationInfo2, setLocationInfo2] = useState({ loading: false, error: { errorMessage: "", success: false }, value: contactList[2].value[1] });
    const locationInfo2Handler = (event, err, loading) => setLocationInfo2({ value: (event ? event.target.value : locationInfo2.value), error: (err ? err : locationInfo2.err), loading: (loading ? loading : locationInfo2.loading) });

    const productDetail = [
        [
            { email: true, required: true, title: contactList[0].title, id: contactList[0].id, idItem: contactList[0].id, indexValue: 0, type: "text", value: emailInfo.value, error: emailInfo.error, loading: emailInfo.loading, change: emailInfoHandler, placeholder: contactList[0].title }
        ],
        [
            { required: true, title: contactList[1].title, id: contactList[1].id, idItem: contactList[1].id, indexValue: 0, type: "text", value: telInfo.value, error: telInfo.error, loading: telInfo.loading, change: telInfoHandler, placeholder: contactList[1].title }
        ],
        [
            { required: true, title: contactList[2].title, id: `${contactList[2].id}1`, idItem: contactList[2].id, indexValue: 0, type: "text", value: locationInfo1.value, error: locationInfo1.error, loading: locationInfo1.loading, change: locationInfo1Handler, placeholder: contactList[2].title },
            { required: true, title: contactList[2].title, id: `${contactList[2].id}2`, idItem: contactList[2].id, indexValue: 1, type: "text", value: locationInfo2.value, error: locationInfo2.error, loading: locationInfo2.loading, change: locationInfo2Handler, placeholder: contactList[2].title }
        ]
    ];

    return (
        <React.Fragment>
            <Container fluid className="p-5">
                <ProductTitle textLight={lightId} textBold={boldId} titleType="h1" />
                {productDetail.map((c, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Row className="mt-4">
                                {
                                    c.map(i => {
                                        return (
                                            <Col md={12 / c.length < 6 ? 6 : 12 / c.length} key={`${i.idItem}${i.indexValue}`} className="mt-4 p-md-3 p-2">
                                                <h4 className="mb-3 custom-header">{i.title}</h4>
                                                <ContactFormItem
                                                    {...i}
                                                    reference={i.reference ? i.reference : null}
                                                    admin
                                                    button={<button onClick={() => saveHandler(i)} className="btn custom-btn py-2 px-3 ml-2">
                                                        {i.loading ? <div className="spinner">
                                                            <div className="bounce1"></div>
                                                            <div className="bounce2"></div>
                                                            <div className="bounce3"></div>
                                                        </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                                                    </button>} />
                                                {i.error && i.error.errorMessage !== "" ?
                                                    i.error.success ?
                                                        <span className="custom-success-message">{i.error.errorMessage}</span>
                                                        :
                                                        <span className="custom-error-message">{i.error.errorMessage}</span> : null}
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </React.Fragment>
                    );
                })}
            </Container>
        </React.Fragment >
    );
};

export const componentName = "Contacto";
export default Contacto;