import React, { useState, useEffect, useCallback } from 'react';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

//Bootstrap
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavItem.css';

const iconArr = [faShoppingCart];

const NavItem = (props) => {
    const [bgTransparent, setBgTransparent] = useState(props.initSection ? true : false);

    const handleBgTransparent = useCallback(() => {
        if (props.initSection) {
            if (document.documentElement.scrollTop >= 140) {
                setBgTransparent(false);
            } else {
                setBgTransparent(true);
            }
        }
    }, [props.initSection]);

    useEffect(() => {
        window.addEventListener('scroll', handleBgTransparent, true);
        return (() => {
            window.removeEventListener('scroll', handleBgTransparent, true);
        });
    }, [handleBgTransparent]);

    const ChildComponent = () => {
        const icon = iconArr.find(i => i.iconName === props.title);
        if (icon) {
            return <FontAwesomeIcon icon={icon} />
        } else {
            return props.title;
        }
    }
    return (
        <Nav.Item className={props.classes}>
            <LinkContainer to={{ pathname: props.path }} exact={props.exact} activeClassName="custom-active">
                {/* {props.brand ? <Navbar.Brand>{props.title}</Navbar.Brand> */}
                {props.brand ?
                    props.mobile ?
                        <Navbar.Brand><img src={props.img} alt={props.title} style={{ width: (bgTransparent ? '5rem' : '3.5rem') }} /></Navbar.Brand>
                        :
                        <Navbar.Brand><img src={props.img} alt={props.title} style={{ width: (bgTransparent ? '15rem' : '9rem') }} /></Navbar.Brand>
                    :
                    <Nav.Link>
                        <ChildComponent />
                    </Nav.Link>}
            </LinkContainer>
        </Nav.Item>
    );
};

export default NavItem;