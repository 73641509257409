import React from 'react';

import './TitleLightBold.css';

const TitleLightBold = (props) => {
    const classesBold = ["custom-font-wight-bolder"];
    if (props.boldDisplayBlock) {
        classesBold.push("d-block");
    }
    let returnComponent = null;
    switch (props.titleType) {
        case "h1":
            returnComponent = <h1 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h1>;
            break;
        case "h2":
            returnComponent = <h2 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h2>;
            break;
        case "h3":
            returnComponent = <h3 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h3>;
            break;
        case "h4":
            returnComponent = <h4 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h4>;
            break;
        case "h5":
            returnComponent = <h5 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h5>;
            break;
        case "h6":
            returnComponent = <h6 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h6>
            break;
        default:
            returnComponent = <h1 className="custom-font-weight-lighter text-center">{props.textLight}<strong className={classesBold.join(' ')}>{props.textBold}</strong></h1>;
            break;
    }
    return (
        returnComponent
    );
};

export default TitleLightBold;