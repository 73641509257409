import React, { useState, useCallback } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// import './ProductDetail.css';
import CustomModal from '../../../CustomModal/CustomModal';
import ContactFormItem from '../../../Contact/ContactFormItem/ContactFormItem';
import ProductTitle from '../../ProductTitle/ProductTitle';
import { updateProduct, switchProduct } from '../../../../store/actions/data';

const defaultBtnLeft = ["btn", "custom-btn", "custom-btn-left", "py-sm-3", "px-sm-4", "py-2", "px-3"];
const defaultBtnRight = ["btn", "custom-btn", "custom-btn-right", "py-sm-3", "px-sm-4", "py-2", "px-3"];

const ProductDetail = (props) => {
    const ID_SERVICIOS = useSelector(state => state.data.products.ID_SERVICIOS);
    const ID_REPUESTOS = useSelector(state => state.data.products.ID_REPUESTOS);
    const arrId = props.id.split("-");
    const lightId = `${arrId[0]}-`;
    const boldId = `${arrId[1]}`;
    const dispatch = useDispatch();

    const [tempItem, setTempItem] = useState(null);
    const { register: registerImg1, handleSubmit: handleSubmitImg1 } = useForm();
    const { register: registerImg2, handleSubmit: handleSubmitImg2 } = useForm();
    const { register: registerImg3, handleSubmit: handleSubmitImg3 } = useForm();
    const { register: registerImg4, handleSubmit: handleSubmitImg4 } = useForm();

    const saveHandler = useCallback(async (item, save) => {
        if (item.type === "file" && !save) {
            setTempItem(item);
        } else {
            if (item.required && item.value === "") {
                item.change(null, { errorMessage: "Este campo no puede estar vacío", success: false }, false);
                return;
            }
            item.change(null, { errorMessage: "", success: false }, true);
            try {
                await dispatch(updateProduct(props, item));
                item.change(null, { errorMessage: "Guardado correctamente", success: true }, false)
            } catch (err) {
                item.change(null, { errorMessage: err.message, success: false }, false)
            }
        }
    }, [dispatch, props]);

    const onSubmit = useCallback(async (data) => {
        const item = tempItem
        setTempItem(null);
        item.change(null, { errorMessage: "", success: false }, true);
        for (const key in data) {
            const element = data[key];
            if (element.length === 0) {
                item.change(null, { errorMessage: "Debe seleccionar un archivo antes de guardar", success: false }, false)
            } else {
                try {
                    let res = await uploadHandler(element[0]);
                    if (!res.data.error) {
                        item.value = res.data.url;
                        saveHandler(item, true);
                    } else {
                        item.change(null, { errorMessage: res.data.message, success: false }, false)
                    }
                } catch (err) {
                    item.change(null, { errorMessage: err, success: false }, false);
                }
            }
        }
    }, [tempItem, saveHandler]);

    const uploadHandler = async (file) => {
        const formData = new FormData();
        formData.append('avatar', file);
        try {
            const response = await axios.post('http://www.prostringmachine.com/php/upload.php', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            });
            return response;
        } catch (err) {
            throw err;
        }
    };


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [deletedOk, setDeletedOk] = useState(false);
    const [deletedFail, setDeletedFail] = useState(false);
    const messageModalDesactivate = `¿Estás seguro de querer desactivar el producto ${props.title} (${props.id})? El mismo solo será dado de baja para los usuarios finales, podés verlo en la solapa de "INACTIVOS" y volverlo a activar cuando quieras.`;
    const messageModalActivate = `¿Estás seguro de querer activar el producto ${props.title} (${props.id})? El mismo será visble para todos los usuarios finales, podés verlo en la solapa de "ACTIVOS" y volverlo a desactivar cuando quieras.`;
    const [active, setActive] = useState(!props.inactive);
    let classesBtnLeft = [];
    let classesBtnRight = [];

    if (active) {
        classesBtnLeft = [...defaultBtnLeft, "custom-btn-selected"];
        classesBtnRight = [...defaultBtnRight, "custom-btn-non-selected"];
    } else {
        classesBtnLeft = [...defaultBtnLeft, "custom-btn-non-selected"];
        classesBtnRight = [...defaultBtnRight, "custom-btn-selected"];
    }

    const [title, setTitle] = useState({ loading: false, error: { errorMessage: "", success: false }, value: props.title });
    const titleHandler = (event, err, loading) => setTitle({ value: (event ? event.target.value : title.value), error: (err ? err : title.err), loading: (loading ? loading : title.loading) });
    const [price, setPrice] = useState({ loading: false, error: { errorMessage: "", success: false }, value: props.price });
    const priceHandler = (event, err, loading) => setPrice({ value: (event ? event.target.value : price.value), error: (err ? err : price.err), loading: (loading ? loading : price.loading) });
    const [description, setDescription] = useState({ loading: false, error: { errorMessage: "", success: false }, value: props.description });
    const descriptionHandler = (event, err, loading) => setDescription({ value: (event ? event.target.value : description.value), error: (err ? err : description.err), loading: (loading ? loading : description.loading) });
    const [image1, setImage1] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.imgDetails ? props.imgDetails.length > 0 ? props.imgDetails[0] : "" : "") });
    const image1Handler = (event, err, loading) => setImage1({ value: (event ? event.target.value : image1.value), error: (err ? err : image1.err), loading: (loading ? loading : image1.loading) });
    const [image2, setImage2] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.imgDetails ? props.imgDetails.length > 1 ? props.imgDetails[1] : "" : "") });
    const image2Handler = (event, err, loading) => setImage2({ value: (event ? event.target.value : image2.value), error: (err ? err : image2.err), loading: (loading ? loading : image2.loading) });
    const [image3, setImage3] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.imgDetails ? props.imgDetails.length > 2 ? props.imgDetails[2] : "" : "") });
    const image3Handler = (event, err, loading) => setImage3({ value: (event ? event.target.value : image3.value), error: (err ? err : image3.err), loading: (loading ? loading : image3.loading) });
    const [image4, setImage4] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.imgDetails ? props.imgDetails.length > 3 ? props.imgDetails[3] : "" : "") });
    const image4Handler = (event, err, loading) => setImage4({ value: (event ? event.target.value : image4.value), error: (err ? err : image4.err), loading: (loading ? loading : image4.loading) });
    const [measure, setMeasure] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.tableDetails ? props.tableDetails.measures ? props.tableDetails.measures.outBox ? props.tableDetails.measures.outBox : "" : "" : "") });
    const measureHandler = (event, err, loading) => setMeasure({ value: (event ? event.target.value : measure.value), error: (err ? err : measure.err), loading: (loading ? loading : measure.loading) });
    const [measureBox, setMeasureBox] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.tableDetails ? props.tableDetails.measures ? props.tableDetails.measures.inBox ? props.tableDetails.measures.inBox : "" : "" : "") });
    const measureBoxHandler = (event, err, loading) => setMeasureBox({ value: (event ? event.target.value : measureBox.value), error: (err ? err : measureBox.err), loading: (loading ? loading : measureBox.loading) });
    const [pinzas, setPinzas] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.tableDetails ? props.tableDetails.pinzas ? true : false : false) });
    const pinzasHandler = (event, err, loading) => setPinzas({ value: (event ? event.target.checked : pinzas.value), error: (err ? err : pinzas.err), loading: (loading ? loading : pinzas.loading) });
    const [garantia, setGarantia] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.tableDetails ? props.tableDetails.guarantee ? props.tableDetails.guarantee : "" : "") });
    const garantiaHandler = (event, err, loading) => setGarantia({ value: (event ? event.target.value : garantia.value), error: (err ? err : garantia.err), loading: (loading ? loading : garantia.loading) });
    const [extras, setExtras] = useState({ loading: false, error: { errorMessage: "", success: false }, value: (props.tableDetails ? props.tableDetails.extras ? props.tableDetails.extras : "" : "") });
    const extrasHandler = (event, err, loading) => setExtras({ value: (event ? event.target.value : extras.value), error: (err ? err : extras.err), loading: (loading ? loading : extras.loading) });

    const [azul, setAzul] = useState((props.tableDetails ? props.tableDetails.colorsAvailable ? props.tableDetails.colorsAvailable.includes("Azul") : false : false));
    const azulHandler = (event) => {
        setAzul((event ? event.target.checked : azul.value));
        if (!azul && negro && silver) {
            colorsHandler(null, { errorMessage: "Se pueden seleccionar 2 colores como máximo", success: false }, false);
            setAzul(false);
            document.getElementById("Azul").checked = false;
            return;
        }
        colorsHandler(null, { errorMessage: "", success: false }, false)
    }
    const [negro, setNegro] = useState((props.tableDetails ? props.tableDetails.colorsAvailable ? props.tableDetails.colorsAvailable.includes("Negro") : false : false));
    const negroHandler = (event) => {
        setNegro((event ? event.target.checked : negro.value));
        if (!negro && azul && silver) {
            colorsHandler(null, { errorMessage: "Se pueden seleccionar 2 colores como máximo", success: false }, false);
            setNegro(false);
            document.getElementById("Negro").checked = false;
            return;
        }
        colorsHandler(null, { errorMessage: "", success: false }, false)
    }
    const [silver, setSilver] = useState((props.tableDetails ? props.tableDetails.colorsAvailable ? props.tableDetails.colorsAvailable.includes("Silver") : false : false));
    const silverHandler = (event) => {
        setSilver((event ? event.target.checked : silver.value));
        if (!silver && azul && negro) {
            colorsHandler(null, { errorMessage: "Se pueden seleccionar 2 colores como máximo", success: false }, false);
            setSilver(false);
            document.getElementById("Silver").checked = false;
            return;
        }
        colorsHandler(null, { errorMessage: "", success: false }, false)
    }
    const [colors, setColors] = useState({ loading: false, error: { errorMessage: "", success: false } });
    const colorsHandler = (event, err, loading) => setColors({ error: (err ? err : extras.err), loading: (loading ? loading : extras.loading) });

    const productDetail = [
        [
            { required: true, showWithoutImage: true, title: "Nombre del producto", id: "title", type: "text", value: title.value, error: title.error, loading: title.loading, change: titleHandler, placeholder: "Nombre" },
            { required: true, showWithoutImage: true, title: "Precio", id: "price", type: "number", value: price.value, error: price.error, loading: price.loading, change: priceHandler, placeholder: "Precio" }
        ],
        [
            { required: true, showWithoutImage: true, title: "Descripción", id: "description", textarea: true, value: description.value, error: description.error, loading: description.loading, change: descriptionHandler, placeholder: "Descripción", cols: "", rows: "3" }
        ],
        [
            { showWithoutImage: false, title: "Imagen Principal", id: "imgDetails1", type: "file", reference: registerImg1, submit: handleSubmitImg1(onSubmit), src: (props.imgDetails ? props.imgDetails.length > 0 ? props.imgDetails[0] : "" : ""), value: image1.value, accept: "image/png, image/jpg, image/jpeg", error: image1.error, loading: image1.loading, change: image1Handler, placeholder: "Imagen 1" },
            { showWithoutImage: false, title: "Imagen 2", id: "imgDetails2", type: "file", reference: registerImg2, submit: handleSubmitImg2(onSubmit), src: (props.imgDetails ? props.imgDetails.length > 1 ? props.imgDetails[1] : "" : ""), value: image2.value, accept: "image/png, image/jpg, image/jpeg", error: image2.error, loading: image2.loading, change: image2Handler, placeholder: "Imagen 2" },
            { showWithoutImage: false, title: "Imagen 3", id: "imgDetails3", type: "file", reference: registerImg3, submit: handleSubmitImg3(onSubmit), src: (props.imgDetails ? props.imgDetails.length > 2 ? props.imgDetails[2] : "" : ""), value: image3.value, accept: "image/png, image/jpg, image/jpeg", error: image3.error, loading: image3.loading, change: image3Handler, placeholder: "Imagen 3" },
            { showWithoutImage: false, title: "Imagen 4", id: "imgDetails4", type: "file", reference: registerImg4, submit: handleSubmitImg4(onSubmit), src: (props.imgDetails ? props.imgDetails.length > 3 ? props.imgDetails[3] : "" : ""), value: image4.value, accept: "image/png, image/jpg, image/jpeg", error: image4.error, loading: image4.loading, change: image4Handler, placeholder: "Imagen 4" }
        ],
        [
            { required: true, showWithoutImage: false, title: "Medidas (Total)", id: "outBox", type: "text", value: measure.value, error: measure.error, loading: measure.loading, change: measureHandler, placeholder: "Medidas (Total)" },
            { showWithoutImage: false, title: "Medidas (Caja)", id: "inBox", type: "text", value: measureBox.value, error: measureBox.error, loading: measureBox.loading, change: measureBoxHandler, placeholder: "Medidas (Caja)" },
        ],
        [
            {
                showWithoutImage: false, title: "Colores (Máx. 2)", id: "colorsAvailable", type: "colors", value: "", change: colorsHandler, error: colors.error, loading: colors.loading, checkboxs:
                    [
                        { id: "Azul", change: azulHandler, checked: azul },
                        { id: "Negro", change: negroHandler, checked: negro },
                        { id: "Silver", change: silverHandler, checked: silver }
                    ]
            },
            { showWithoutImage: false, title: "Incluye Pinzas", id: "pinzas", type: "checkbox", value: "", change: pinzasHandler, checked: pinzas.value, error: pinzas.error, loading: pinzas.loading },
        ],
        [
            { showWithoutImage: false, title: "Garantía (meses)", id: "guarantee", type: "number", value: garantia.value, error: garantia.error, loading: garantia.loading, change: garantiaHandler, placeholder: "Garantía (meses)" },
            { showWithoutImage: false, title: "Extras", id: "extras", type: "text", value: extras.value, error: extras.error, loading: extras.loading, change: extrasHandler, placeholder: "Extras" }
        ]
    ];

    const deleteProductHandler = useCallback(async () => {
        //setModal(false);
        setError(null);
        setLoading(true);
        setDeletedOk(false);
        setDeletedFail(false);
        try {
            await dispatch(switchProduct(props.id, props.idType, !props.inactive))
            setLoading(false);
            setDeletedOk(true);
            setActive(!active)
        } catch (err) {
            setError(err.message);
            setLoading(false);
            setDeletedFail(true);
        };
    }, [dispatch, props.id, props.idType, props.inactive, active]);

    const closeModalHandler = () => {
        setModal(false);
        setError(null);
        setLoading(false);
        setDeletedOk(false);
        setDeletedFail(false);
    }

    return (
        <React.Fragment>
            <Container fluid className="p-5">
                <ProductTitle textLight={lightId} textBold={boldId} titleType="h1" />
                <Row className="justify-content-center align-items-center py-5">
                    <button className={classesBtnLeft.join(' ')} onClick={() => setModal(true)} disabled={active ? true : false}>Activar</button>
                    <button className={classesBtnRight.join(' ')} onClick={() => setModal(true)} disabled={!active ? true : false}>Desactivar</button>
                </Row>
                {productDetail.map((c, index) => {
                    // let errorMessage = null;
                    // if (Array.isArray(c)) {
                    return (
                        <React.Fragment key={index}>
                            <Row className="mt-4">
                                {
                                    c.map(i => {
                                        // for (const k in errors) {
                                        //     if (k === i.id) {
                                        //         errorMessage = errors[k].message ? errors[k].message : null;
                                        //     }
                                        // }
                                        if ((i.showWithoutImage) || (props.idType !== ID_SERVICIOS && props.idType !== ID_REPUESTOS)) {
                                            return (
                                                <Col md={12 / c.length < 6 ? 6 : 12 / c.length} key={i.id} className="mt-4 p-md-3 p-2">
                                                    <h4 className="mb-3 custom-header">{i.title}</h4>
                                                    {i.type === "file" ? i.src !== "" ? <div className="text-center"><img src={`../../../${i.src}`} alt={i.title} className="img-fluid p-3" style={{ maxWidth: '300px', width: '100%' }} /></div> : <div className="text-center"><img src={`../../../assets/images/none.png`} alt={i.title} className="img-fluid p-3" style={{ maxWidth: '300px', width: '100%' }} /></div> : null}
                                                    {i.submit ?
                                                        <form className="custom-form" onSubmit={i.submit}>
                                                            <ContactFormItem
                                                                {...i}
                                                                reference={i.reference ? i.reference : null}
                                                                admin
                                                                button={<button type="submit" onClick={() => saveHandler(i)} className="btn custom-btn py-2 px-3 ml-2">
                                                                    {i.loading ? <div className="spinner">
                                                                        <div className="bounce1"></div>
                                                                        <div className="bounce2"></div>
                                                                        <div className="bounce3"></div>
                                                                    </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                                                                </button>} />
                                                        </form>
                                                        :
                                                        <ContactFormItem
                                                            {...i}
                                                            reference={i.reference ? i.reference : null}
                                                            admin
                                                            button={<button onClick={() => saveHandler(i)} className="btn custom-btn py-2 px-3 ml-2">
                                                                {i.loading ? <div className="spinner">
                                                                    <div className="bounce1"></div>
                                                                    <div className="bounce2"></div>
                                                                    <div className="bounce3"></div>
                                                                </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                                                            </button>} />}
                                                    {i.error && i.error.errorMessage !== "" ?
                                                        i.error.success ?
                                                            <span className="custom-success-message">{i.error.errorMessage}</span>
                                                            :
                                                            <span className="custom-error-message">{i.error.errorMessage}</span> : null}
                                                </Col>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </Row>
                        </React.Fragment>
                    );
                    // } else {
                    //     // for (const k in errors) {
                    //     //     if (k === c.id) {
                    //     //         errorMessage = errors[k].message ? errors[k].message : null;
                    //     //     }
                    //     // }
                    //     return (
                    //         <React.Fragment key={c.id}>
                    //             <Row className="mt-4">
                    //                 <Col className="mt-4">
                    //                     {/* <form className="custom-form" onSubmit={handleSubmit(onSubmit)}> */}
                    //                     <h4 className="mb-3 custom-header">{c.title}</h4>
                    //                     <ContactFormItem
                    //                         {...c}
                    //                         reference={null}
                    //                         admin
                    //                         button={<button onClick={() => saveHandler(c)} className="btn custom-btn py-2 px-3 ml-2">
                    //                             {c.loading ? <div className="spinner">
                    //                                 <div className="bounce1"></div>
                    //                                 <div className="bounce2"></div>
                    //                                 <div className="bounce3"></div>
                    //                             </div> : <FontAwesomeIcon icon={faSave} style={{ fontSize: '1.2rem' }} />}
                    //                         </button>} />
                    //                     {c.error && c.error.errorMessage !== "" ? c.error.success ? <span className="custom-success-message">{c.error.errorMessage}</span> : <span className="custom-error-message">{c.error.errorMessage}</span> : null}
                    //                     {/* </form> */}
                    //                 </Col>
                    //             </Row>
                    //         </React.Fragment>
                    //     )
                    // }
                })}
            </Container>
            <CustomModal
                show={modal}
                onHide={setModal}
                title="Aviso"
                body={loading ? "Cargando..." :
                    deletedOk ? (!active ? "Producto desactivado correctamente!" : "Producto activado correctamente!") :
                        deletedFail ? error : (active ? messageModalDesactivate : messageModalActivate)}
                showFooter={loading ? false : true}
                primaryButton={{ show: (loading ? false : true), href: null, click: (deletedOk || deletedFail ? closeModalHandler : deleteProductHandler), text: (deletedOk || deletedFail ? "Ok" : "Sí") }}
                secondaryButton={{ show: (loading || deletedOk || deletedFail ? false : true), href: null, click: () => setModal(false), text: "Cancelar" }}
            />
        </React.Fragment >
    );
};

export default ProductDetail;