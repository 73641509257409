import React from 'react';

//Bootstrap
import { Row, Col, Table } from 'react-bootstrap';

import './TableDetails.css';

const TableDetails = (props) => {
    return (
        <Row className="p-5 justify-content-center">
            <Col>
                <Table responsive striped className="custom-table custom-text-light">
                    <tbody>
                        <tr>
                            <th>Medidas</th>
                            <td>{props.measures.outBox}</td>
                            <td>{props.measures.inBox ? `Caja: ${props.measures.inBox}` : null}</td>
                        </tr>
                        <tr>
                            <th>Colores disponibles</th>
                            {props.colorsAvailable.map(color => {
                                const classes = ["rounded-circle", "custom-option-color", "mr-3"];
                                switch (color) {
                                    case "Azul":
                                        classes.push("custom-option-blue");
                                        break;
                                    case "Negro":
                                        classes.push("custom-option-black");
                                        break;
                                    case "Silver":
                                        classes.push("custom-option-silver");
                                        break;
                                    default:
                                        break;
                                }

                                return (<td key={color}>
                                    <div className="d-flex align-items-center">
                                        <div className={classes.join(' ')}></div>
                                        <div>{color}</div>
                                    </div>
                                </td>)
                            })}
                            {props.colorsAvailable.length === 1 ? <td></td> : null}
                        </tr>
                        {props.pinzas ? <tr>
                            <th>Incluye pinzas</th>
                            {/* <td>Si ({props.pinzasQty})</td> */}
                            <td>Si</td>
                            <td></td>
                        </tr> : null}
                        <tr>
                            <th>Garantía</th>
                            <td>{props.guarantee} Meses</td>
                            <td></td>
                        </tr>
                        {props.extras ? <tr>
                            <th>Extras</th>
                            <td>{props.extras}</td>
                            <td></td>
                        </tr> : null}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default TableDetails;