import React, { useEffect, useState, useCallback } from 'react';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Container } from 'react-bootstrap';

import CountChart from '../../../components/CountChart/CountChart';
import ProductType from '../../../components/Products/ProductType/ProductType';
// import { products } from '../../../data/Products';
// import { initCounter } from '../../../data/GeneralProps';

const Inicio = () => {
    const products = useSelector(state => state.data.products.products);
    const initCounter = useSelector(state => state.data.generalProps.initCounter);
    const [countChartVisible, setCountChartVisible] = useState(false);

    const handleCountChart = useCallback(() => {
        if (document.documentElement.scrollTop >= 170) {
            setCountChartVisible(true);
            window.removeEventListener('scroll', handleCountChart, true);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleCountChart, true);
        return (() => {
            window.removeEventListener('scroll', handleCountChart, true);
        });
    }, [handleCountChart]);

    return (
        <React.Fragment>
            <div>
                <Container fluid>
                    {countChartVisible ? <CountChart visible={countChartVisible} initCounter={initCounter}/> : <div style={{ height: '315px' }}></div>}
                </Container>

                {products.map(p => <ProductType key={p.id} {...p} fromHome={true}/>)}
            </div>
        </React.Fragment>
    );
};

export const componentName = "Inicio";
export default Inicio;