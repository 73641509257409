import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { /*putData,*/ fetchData } from './store/actions/data';

//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

import './App.scss';
import './App.css';
import IndexApp from './containers/IndexApp/IndexApp';
//AdminPage
import Admin from './containers/screens/Admin/Admin';
import SignIn from './containers/screens/Admin/SignIn/SignIn';
import AdminLogged from './containers/screens/Admin/AdminLogged/AdminLogged';

function App() {
  const dataLoaded = useSelector(state => state.data);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    setError(null);
    try {
      // await dispatch(putData());
      await dispatch(fetchData());

    } catch (err) {
      setError(err.message);
    }
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (Object.entries(dataLoaded).length === 0 && !error) {
    return (
      <div className="custom-bg-loader">
        <div className="custom-loader">Cargando...</div>
      </div>
    );
  } else if (error) {
    return (
      <Container fluid className="custom-bg-loader p-5 text-center text-white">
        <Row>
          <Col>
            <h1 className="font-weight-bolder mb-4">Oops</h1>
            <h3 className="font-weight-lighter">Algo anda mal :(</h3>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    // <IndexApp dataLoaded={dataLoaded} />
    <Switch>
      <Route path='/admin/menu' render={() => <AdminLogged />} />
      <Route path='/admin/login' render={() => <SignIn />} />
      <Route path='/admin' render={() => <Admin />} />
      <Route path='/' render={() => <IndexApp dataLoaded={dataLoaded} />} />
    </Switch>
  );
}

export default withRouter(App);

