import React from 'react';

//Bootstrap
import { Col } from 'react-bootstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CardProperty.css'

const CardProperty = (props) => {
    return (
        <Col lg="4" md="5" className="text-center mb-5">
            <div className="p-4 custom-card-service">
                <span className="custom-card-icon">
                    <span><FontAwesomeIcon icon={props.icon} className="text-white"/></span>
                </span>
                <h3 className="custom-card-title">{props.title}</h3>
                <p className="custom-text-light">{props.description}</p>
            </div>
        </Col>
    );
};

export default CardProperty;