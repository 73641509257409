import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux';

//Bootstrap
import { Container, Row, Col, Carousel } from 'react-bootstrap';

import './ProductDetail.css';
import CardProperty from '../../CardProperty/CardProperty';
import ProductItem from '../ProductItem/ProductItem';
import ProductTitle from '../ProductTitle/ProductTitle';
import MainDetails from './MainDetails/MainDetails';
import TableDetails from './TableDetails/TableDetails';
import CustomModal from '../../CustomModal/CustomModal';
// import { products, productsFeatures, ID_MAQUINAS, ID_REPUESTOS } from '../../../data/Products';

const ProductDetail = (props) => {
    const products = useSelector(state => state.data.products.products);
    const productsFeatures = useSelector(state => state.data.products.productsFeatures);
    const ID_MAQUINAS = useSelector(state => state.data.products.ID_MAQUINAS);
    const ID_REPUESTOS = useSelector(state => state.data.products.ID_REPUESTOS);
    const contactScreen = useSelector(state => state.data.generalProps.contactScreen);
    const telProperty = contactScreen.contactList[1].value[0];
    const tel = `tel:${telProperty}`;

    const [showModalBuy, setShowModalBuy] = useState(false);
    const [relatedItems, setRelatedItems] = useState([]);
    const handleShowModalBuyOpen = () => setShowModalBuy(true);
    const handleShowModalBuyClose = () => setShowModalBuy(false);

    useEffect(() => {
        const maquinas = products.filter(p => p.id === ID_MAQUINAS);
        // const repuestos = products.filter(p => p.id === ID_REPUESTOS);
        const maquinasSinActual = { pathname: maquinas[0].pathname, list: [...maquinas[0].productsList.filter(m => m.id !== props.id)] };
        // const repuestosSinActual = { pathname: repuestos[0].pathname, list: [...repuestos[0].productsList.filter(r => r.id !== props.id)] };
        // const random1 = Math.floor(Math.random() * maquinasSinActual.list.length - 1) + 1;
        // const random2 = Math.floor(Math.random() * repuestosSinActual.list.length - 1) + 1;
        // let random3 = Math.floor(Math.random() * repuestosSinActual.list.length - 1) + 1;
        // while (random3 === random2) {
        //     random3 = Math.floor(Math.random() * repuestosSinActual.list.length - 1) + 1;
        // }
        setRelatedItems([
            { pathname: maquinasSinActual.pathname, product: maquinasSinActual.list[0] },
            { pathname: maquinasSinActual.pathname, product: maquinasSinActual.list[1] },
            { pathname: maquinasSinActual.pathname, product: maquinasSinActual.list[2] }]);
            // { pathname: repuestosSinActual.pathname, product: repuestosSinActual.list[random2] },
            // { pathname: repuestosSinActual.pathname, product: repuestosSinActual.list[random3] }]);
    }, [props.id, products, ID_MAQUINAS, ID_REPUESTOS]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row className="p-5 justify-content-center">
                    <Col lg="7" xl="5" className="mb-mb-0 mb-5">
                        <Carousel controls={true} interval={2000}>
                            {props.imgDetails.map(i => <Carousel.Item key={i}>
                                <img src={i} alt={props.alt} className="img-fluid" />
                            </Carousel.Item>)}
                        </Carousel>
                    </Col>
                    <MainDetails {...props} click={handleShowModalBuyOpen} />
                </Row>
                <div>
                    <ProductTitle textLight="Especificaciones" textBold=" Técnicas" titleType="h3" className="pt-5 pt-lg-0" />
                    <TableDetails {...props.tableDetails} />
                </div>
                <Row className="p-5 justify-content-center">
                    {productsFeatures.map(p => <CardProperty key={p.id} {...p} />)}
                </Row>
                <hr className="custom-hr" />
                <div>
                    <ProductTitle textLight="Productos" textBold=" Relacionados" titleType="h3" className="pt-5" />
                    <Row className="p-5 justify-content-center">
                        {relatedItems.map(i =>
                            <ProductItem key={i.product.id}
                                {...i.product}
                                fromHome={true}
                                withImage={i.product.img ? true : false}
                                pathname={i.pathname}
                                flex={{
                                    xs: "10",
                                    sm: "6",
                                    md: "4",
                                    lg: "4",
                                    xl: "3"
                                }}
                            />
                        )}
                    </Row>
                </div>
            </Container>
            <CustomModal
                show={showModalBuy}
                onHide={handleShowModalBuyClose}
                title="Proximamente"
                body="Por el momento no disponemos con la posibilidad de comprar por acá, pero comunicate con nosotros y vas a recibir toda la ayuda y atención necesaria."
                showFooter={true}
                primaryButton={{ show: true, href: tel, click: null, text: "Llamar" }}
                secondaryButton={{ show: true, href: null, click: handleShowModalBuyClose, text: "Cerrar" }}
            />

        </React.Fragment>
    );
};

export default ProductDetail;