import React, { useState } from 'react';

//Bootstrap
import { Container, Row } from 'react-bootstrap';

import ProductTitle from '../../ProductTitle/ProductTitle';
import ProductList from '../../ProductList/Admin/ProductList';
import '../ProductType.css';
import './ProductType.css';
import { NavLink } from 'react-router-dom';

const defaultBtnLeft = ["btn", "custom-btn", "custom-btn-left", "py-sm-3", "px-sm-4", "py-2", "px-3"];
const defaultBtnRight = ["btn", "custom-btn", "custom-btn-right", "py-sm-3", "px-sm-4", "py-2", "px-3"];

const ProductType = (props) => {
    const [active, setActive] = useState(true);
    let classesBtnLeft = [];
    let classesBtnRight = [];

    if (active) {
        classesBtnLeft = [...defaultBtnLeft, "custom-btn-selected"];
        classesBtnRight = [...defaultBtnRight, "custom-btn-non-selected"];
    } else {
        classesBtnLeft = [...defaultBtnLeft, "custom-btn-non-selected"];
        classesBtnRight = [...defaultBtnRight, "custom-btn-selected"];
    }

    return (
        <React.Fragment>
            {props.fromHome ? <hr className="custom-hr" /> : null}
            <Container fluid className="py-5">
                <ProductTitle textLight={props.textLight} textBold={props.textBold} titleType={props.fromHome ? "h3" : "h1"} />
                <Row className="justify-content-center align-items-center py-5">
                    <button className={classesBtnLeft.join(' ')} onClick={() => setActive(true)}>Activos</button>
                    <button className={classesBtnRight.join(' ')} onClick={() => setActive(false)}>Inactivos</button>
                </Row>
                <Row className="justify-content-center align-items-center p-5">
                    <ProductList {...props} active={active} />
                </Row>
                {props.fromHome ? (<button className="btn custom-btn d-block m-auto px-4 py-2">
                    <NavLink to={{ pathname: props.pathname }} className="custom-btn-children">Ver más</NavLink>
                </button>) : null}
            </Container>
        </React.Fragment>
    );
};

export default ProductType;