export const AUTHENTICATE = 'AUTHENTICATE';

export const LOG_OUT = 'LOG_OUT';

let timer;

export const authenticate = (userId, token, expiryTime, refreshTokenId) => {
    return dispatch => {
        dispatch(setLogoutTimer(expiryTime));
        dispatch({ type: AUTHENTICATE, userId: userId, token: token, refreshTokenId: refreshTokenId });
    };
};

export const initialize = (userId, token, refreshTokenId) => {
    return dispatch => {
        dispatch({ type: AUTHENTICATE, userId: userId, token: token, refreshTokenId: refreshTokenId });
    };
};

export const logOut = () => {
    clearLogoutTimer();
    localStorage.removeItem('userData');
    return { type: LOG_OUT };
}

export const signIn = (email, password) => {
    return async dispatch => {
        const response = await fetch(
            'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAi7rGvIGlN5e_8mWxVq-3zbtynCKFzbgc',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    returnSecureToken: true
                })
            }
        );

        if (!response.ok) {
            const errorResData = await response.json();
            const errorId = errorResData.error.message;
            let message = 'Ha ocurrido un error inesperado.'
            if (errorId === 'EMAIL_NOT_FOUND') {
                message = 'El email ingresado no existe';
            } else if (errorId === 'INVALID_PASSWORD') {
                message = 'La contraseña ingresada es inválida.';
            } else if (errorId === 'USER_DISABLED') {
                message = 'El usuario ha sido bloqueado.';
            }
            throw new Error(message);
        }

        const resData = await response.json();
        dispatch(authenticate(resData.localId, resData.idToken, parseInt(resData.expiresIn) * 1000, resData.refreshToken));

        const expirationDate = new Date(
            new Date().getTime() + parseInt(resData.expiresIn) * 1000
        );
        saveDataToStorage(resData.idToken, resData.localId, expirationDate, resData.refreshToken);
    };
};

export const refreshToken = () => {
    return async (dispatch, getState) => {
        const refreshTokenId = getState().auth.refreshTokenId;
        const response = await fetch(
            'https://securetoken.googleapis.com/v1/token?key=AIzaSyAi7rGvIGlN5e_8mWxVq-3zbtynCKFzbgc',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: refreshTokenId
                })
            }
        );

        if (!response.ok) {
            // const errorResData = await response.json();
            // const errorId = errorResData.error.message;
            let message = 'Ha ocurrido un error inesperado.'
            /*if (errorId === 'TOKEN_EXPIRED') {
                message = 'El email ingresado no existe';
            } else if (errorId === 'INVALID_REFRESH_TOKEN') {
                message = 'La contraseña ingresada es inválida.';
            } else if (errorId === 'USER_DISABLED') {
                message = 'El usuario ha sido bloqueado.';
            }*/
            throw new Error(message);
        }

        const resData = await response.json();
        dispatch(authenticate(resData.user_id, resData.id_token, parseInt(resData.expires_in) * 1000, resData.refresh_token));

        const expirationDate = new Date(
            new Date().getTime() + parseInt(resData.expires_in) * 1000
        );
        saveDataToStorage(resData.id_token, resData.user_id, expirationDate, resData.refresh_token);
    };
};

const saveDataToStorage = (token, userId, expirationDate, refreshTokenId) => {
    localStorage.setItem(
        'userData',
        JSON.stringify({
            token: token,
            userId: userId,
            expiryDate: expirationDate.toISOString(),
            refreshTokenId: refreshTokenId
        })
    );
};

const clearLogoutTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
};

const setLogoutTimer = expirationTime => {
    return dispatch => {
        timer = setTimeout(() => {
            dispatch(refreshToken());
        }, expirationTime);
    };
};
